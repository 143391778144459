import React, { Component } from 'react'
import {Auth0Context} from './contexts/auth-context'
import Utils from "./modules/utils"
import $ from 'jquery'

class LaFashionPdfPage extends Component {

  static contextType = Auth0Context

  constructor(props) {
    super(props)
    this.state = {
      results: []
    }
  }

  submit = () => {
    let styleNumber = $('#stylenumber').val()
    if (styleNumber.length < 5) return

    let data = {styleNumber}

    Utils.post('la_fashion_pdf', data, (err, res) => {
      if (err) {
        alert('There was an error when trying to get la fashion pdf.')
      } else {
        let {results} = this.state
        results.push(res.data.url)
        this.setState({results})
      }
    })
  }

  render() {
    let {results} = this.state

    return (
      <div style={{padding: '50px 60px', whiteSpace: 'nowrap'}}>
        <h1 style={{marginTop: '25px', marginBottom: '35px'}}>LA Fashion PDF</h1>
        <input id="stylenumber" placeholder="Enter style number base" style={{width: '400px', padding: '10px'}}/> &nbsp;&nbsp;
        <button style={{padding: '10px', cursor: 'pointer'}} onClick={this.submit}>Submit</button>
        <br/>
        <br/>
        {
          results.length
          ?
          <div>
            Results:<br/>
            {results.reverse().map(row => <div><a href={row} target="_blank">{row}</a></div>)}
          </div>
          :
          null
        }
      </div>
    )
  }
}


export default LaFashionPdfPage

import React, { Component } from 'react'
import $ from 'jquery'
import _ from 'lodash'
import CadCard from './CadCard.js'
import cadFilter from './components/cads/cad-filter.js'
import AtsFilter from './components/cads/ats-filter.js'
import cadSearchParams from './components/cads/cad-search-params.js'
import {getPdfUrl, getExcelUrl} from './components/cads/get-pdf-url.js'
import getOrderPanels from './components/cads/get-order-panels.js'
import {guestStatusOptions, customerOnlyOptions, cadStatusOptions, warehouseStatusOptions} from './components/cads/status-options.js'
import {imageClick, cadViewer, arrowKeyboardClick} from './components/cads/fullscreen-cadviewer.js'
import BulkEditModal from './components/cads/bulk-edit-modal.js'
import updateMultiSearchMessage from './components/cads/multi-search.js'
import compareMultiSearchIndex from './components/cads/compare.js'
import './CadsPage.css'
import {marginsOptionsAdmin} from './getApiEndpoint.js';
import {sortMapOptions} from './components/purchase-orders/sort-map-id-name-options.js'
import ExportToExcelModal from './components/cads/export-to-excel-modal.js'
import ShowHideModal from './components/cads/show-hide-modal.js'
import CadsLoadingBox from './components/cads/loading-box.js'
import {getDifferenceInDays} from './components/cads/date.js'
import {isProduction, isDesign, isCustomer, isWarehouse, isInventory} from './components/cads/is-role.js'
import CadFilterSection from './components/cads/cad-filter-section.js'
import {updateCadsSalesHistoryByStyleNumber, updateCadsSalesHistory} from './components/cads/filter-orders.js'
import CadIdsAccordion from './components/cads/cad-ids-accordion.js'
import CadTotalsAccordion from './components/cads/cad-totals-accordion.js'
import {StatusInfoCircle} from './components/cads/status-info-circle.js'
import getHideShowPanels from './components/cads/get-hide-show-panels.js'
import {DefaultContactInfoCircle, SalesHistoryInfoCircle} from './components/cads/contact-info-circle.js'
import {checkAdminPin} from './components/admin/pin.js'
import ContainerNumberTotals from './components/cads/container-number-totals.js'
import ContainerNumber from './components/cads/container-number.js'
import {checkCanSeePrice} from './components/cads/can-see-price.js'
import BulkBrandApproval from './components/cads/bulk-brand-approval.js'
import {canApproveBrandsList} from './components/cads/brand-approval/index.js'
import {
  TextArea, Message, Select, Accordion, Table, Card, Icon, Dropdown, Grid, Button, Input, Checkbox, Menu, Modal, Header, Popup, Tab, Form,
  Dimmer, Loader
} from 'semantic-ui-react'
import { connect } from 'react-redux'
import * as cadActions from './actions/cadActions'
import { bindActionCreators } from 'redux'
import Utils from "./modules/utils"
import {Auth0Context} from "./contexts/auth-context"
import CustomerAPI from './modules/api/customer_api'
import { DateInput } from 'semantic-ui-calendar-react'
import {futureAtsMonthsOptions, next4Months} from './components/cads/future_ats_months.js'
import {getHideableInfo, removeFromHideableInfo} from './components/cads/hideable-info.js'

import {printsFilter, patternFilter, setPrintOptions} from './components/cads/prints/filter'
import {searchToUnarchive, searchToUnarchiveBulk} from './components/cads/search-to-unarchive'
import {getCadsCache} from './components/cads/cache/get'
import {getUpcExcel} from './components/upc/get'
import {customerFields} from './components/customer/options'
import {canEditCads, canSeeContainerTotals} from './components/custom-access/can.js'
import {getShippedCache} from './components/cads/cache/get-shipped'
import {multipleBrandsSelected} from './components/cads/multiple-brands-selected'
import {showScrollToTop, scrollToTopButton, bulkEditCornerButton, bestSellerExportCornerButtons} from './components/cads/scroll-to-top.js'
import './components/cads/scroll-to-top.css'
import {bestSellersModal} from './components/best-sellers/modal'
import {bestSellersBulkButtons} from './components/best-sellers/bulk-buttons'
import {getRepOptions} from './components/cads/get-rep-options'
import {getAccountOptions} from './components/cads/get-account-options'
import {markAsBestSellerModals} from './components/best-sellers/mark-as-modals'
import {bestSellerSuccess} from './components/best-sellers/success'
import {saveBestSeller} from './components/best-sellers/save'
import {getBestSellerData} from './components/cads/get-best-seller-data'

let hideableInfo = getHideableInfo()



class CadsPage extends Component {

  static contextType = Auth0Context

  constructor(props) {
    super(props)
    this.state = {
      loadingCads: true,
      loadingSalesHistoryByStyleNumber: false,
      loadingSalesHistoryByAccount: false,
      loadingVpos: false,
      loadingContainerNumbers: false,
      //loadingSearch: false,
      sliceNum: 200,
      layout: 'desktop',
      salesHistoryAccounts: [],
      salesHistoryAccount: '',
      repNames: [],
      reps: [],
      missingStyles: 0,
      numCadsSelected: 0,
      searchPONumber: false,
      searchContainerNumber: false,
      viewShowHideModalOpen: false,
      searchString: '',
      // searchString2: '',
      multiSearch: '',
      multiSearch2: '',
      showSimilarStyleNumber: '',
      showSimilarStyleNumberImage: '',
      readyToShip: false,
      hasVponumber: false,
      hasVpoLoadingDate: false,
      oversold: false,
      inProgress: false,
      showATSSlider: false,
      showExtraFilters: false,
      refreshCadCards: false,
      cadsSalesHistory: [],
      expandSalesHistory: false,
      bulkModalOpen: false,
      shareALinkModalOpen: false,
      poorSellerAccount: '',
      averageSellerAccount: '',
      bestSellerAccount: '',
      defaultAccount: [],
      bestSellerSuccessVisible: false,
      averageSellerSuccessVisible: false,
      poorSellerSuccessVisible: false,
      poorSellerModalOpen: false,
      bestSellerModalOpen: false,
      showMultiSearch: false,
      viewPlaylistsModalOpen: false,
      playlists: [],
      addToPlaylistModalOpen: false,
      showNewPlaylistSuccess: false,
      showReportQcIssueSuccess: false,

      //sliders
      minOnHand: 0,
      maxOnHand: 0,
      minHoldQty: 0,
      maxHoldQty: 0,
      minUnits: 0,
      maxUnits: 0,
      minFutureUnits: isCustomer() ? 0 : -3000,
      maxFutureUnits: 0,
      minPage: 0,
      maxPage: 800, //treat as 800+
      minHoldAge: 0,
      maxHoldAge: window.defaultMaxHoldAge, //treat as 800+
      minHoldQty: 0,
      maxHoldQty: 0,

      correctPin: false,
      hideContainerNumber: false,
      multiSearchStyleNumbers: [],

      multipleBrandsSelected: false,


      bestSellersModalOpen: false,
      bestSellersCad: null,
    }

    this.resize = this.resize.bind(this)
    this.search = this.search.bind(this)
    this.updateCads = this.updateCads.bind(this)
    this.cadCard = this.cadCard.bind(this)
    this.updateNumSelected = this.updateNumSelected.bind(this)
    this.salesHistoryByStyleNumberGetData = this.salesHistoryByStyleNumberGetData.bind(this)
    this.updateCadsSalesHistory = this.updateCadsSalesHistory.bind(this)
    this.updateCadsSalesHistoryByStyleNumber = this.updateCadsSalesHistoryByStyleNumber.bind(this)
    this.setRepNames = this.setRepNames.bind(this)
    this.viewShowHide = this.viewShowHide.bind(this);
    this.viewShowHideModalClose = this.viewShowHideModalClose.bind(this);
    this.onChangeHideableInput = this.onChangeHideableInput.bind(this)
    this.toggleHideableCheckbox = this.toggleHideableCheckbox.bind(this);
    this.selectAllHideableCheckbox = this.selectAllHideableCheckbox.bind(this);
    this.selectAllCads = this.selectAllCads.bind(this);
    this.updateCheckboxes = this.updateCheckboxes.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.sortCads = this.sortCads.bind(this);
    this.cadStatusOnChange = this.cadStatusOnChange.bind(this)
    this.brandOnChange = this.brandOnChange.bind(this)
    this.divisionOnChange = this.divisionOnChange.bind(this)
    this.categoryOnChange = this.categoryOnChange.bind(this)
    this.clearFilters = this.clearFilters.bind(this)
    this.showATSSliderHandleClick = this.showATSSliderHandleClick.bind(this)
    this.showExtraFiltersHandleClick = this.showExtraFiltersHandleClick.bind(this)
    this.subCategoryOnChange = this.subCategoryOnChange.bind(this)
    this.defaultAccountOnChange = this.defaultAccountOnChange.bind(this)
    this.colorOnChange = this.colorOnChange.bind(this);
    this.fabricationOnChange = this.fabricationOnChange.bind(this);
    this.packsOnChange = this.packsOnChange.bind(this);
    this.seasonOnChange = this.seasonOnChange.bind(this);
    this.dateFromOnChange = this.dateFromOnChange.bind(this);
    this.dateToOnChange = this.dateToOnChange.bind(this);
    this.exportPdf = this.exportPdf.bind(this)
    this.setCadsPerPage = this.setCadsPerPage.bind(this)
    this.downloadPdf = this.downloadPdf.bind(this);
    this.emailPdf = this.emailPdf.bind(this);
    this.downloadExcel = this.downloadExcel.bind(this)
    this.toggleExportByMonth = this.toggleExportByMonth.bind(this);
    this.toggleExportByBrand = this.toggleExportByBrand.bind(this);
    this.toggleExportNoImages = this.toggleExportNoImages.bind(this);
    this.toggleCombineCads = this.toggleCombineCads.bind(this);
    this.toggleExportMargins = this.toggleExportMargins.bind(this)
    this.toggleHideUnitsUnder = this.toggleHideUnitsUnder.bind(this)
    this.hideUnitsUnderOnChange = this.hideUnitsUnderOnChange.bind(this)
    this.expandSalesHistory = this.expandSalesHistory.bind(this);
    this.compressSalesHistory = this.compressSalesHistory.bind(this);
    this.expandSalesHistory = this.expandSalesHistory.bind(this);
    this.isLoading = this.isLoading.bind(this)
    this.showMoreCads = this.showMoreCads.bind(this)
    this.bulkChangeCads = this.bulkChangeCads.bind(this);
    this.bulkModalClose = this.bulkModalClose.bind(this)
    this.poorSellerAccountOnChange = this.poorSellerAccountOnChange.bind(this);
    this.averageSellerAccountOnChange = this.averageSellerAccountOnChange.bind(this);
    this.bestSellerAccountOnChange = this.bestSellerAccountOnChange.bind(this);
    this.markAsPoorSeller = this.markAsPoorSeller.bind(this);
    this.markAsAverageSeller = this.markAsAverageSeller.bind(this)
    this.markAsBestSeller = this.markAsBestSeller.bind(this);
    this.bestSellerSuccessHandleDismiss = this.bestSellerSuccessHandleDismiss.bind(this);
    this.averageSellerSuccessHandleDismiss = this.averageSellerSuccessHandleDismiss.bind(this);
    this.poorSellerSuccessHandleDismiss = this.poorSellerSuccessHandleDismiss.bind(this);
    this.saveBestPoorSeller = this.saveBestPoorSeller.bind(this);
    this.bestSellerModalClose = this.bestSellerModalClose.bind(this);
    this.searchForOnChange = this.searchForOnChange.bind(this)


    this.updateMissingSearchPONumber = this.updateMissingSearchPONumber.bind(this)
    this.toggleMultiSearch = this.toggleMultiSearch.bind(this)
    this.updateMultiSearchStyleNumbers = this.updateMultiSearchStyleNumbers.bind(this)
    this.doingPONumberSearch = this.doingPONumberSearch.bind(this)
    this.last5Searches = this.last5Searches.bind(this)

    this.marginsOnChange = this.marginsOnChange.bind(this);//do i even need this?
    this.futureAtsMonthsOnChange = this.futureAtsMonthsOnChange.bind(this);

    this.repOnChange = this.repOnChange.bind(this);

    this.addToPlaylistModalClose = this.addToPlaylistModalClose.bind(this);
    this.submitNewPlaylist = this.submitNewPlaylist.bind(this);
    this.viewPlaylists = this.viewPlaylists.bind(this);
    this.viewPlaylistsModalClose = this.viewPlaylistsModalClose.bind(this);
    this.getPlaylists = this.getPlaylists.bind(this);
    this.playlistRow = this.playlistRow.bind(this);
    this.closePlaylistGreyBar = this.closePlaylistGreyBar.bind(this);

    this.goToNewPlaylist = this.goToNewPlaylist.bind(this);
    this.goToPlaylistId = this.goToPlaylistId.bind(this);
    this.addToPlaylistRow = this.addToPlaylistRow.bind(this);

    this.addToPlaylist = this.addToPlaylist.bind(this);
    this.removeFromPlaylist = this.removeFromPlaylist.bind(this);

    this.addToLineSheet = this.addToLineSheet.bind(this)
    this.saveAsNewLinesheet = this.saveAsNewLinesheet.bind(this)
    this.sub2OnChange = this.sub2OnChange.bind(this)

    this.shareLink = this.shareLink.bind(this)
    this.addToCart = this.addToCart.bind(this)
    this.removeFromCart = this.removeFromCart.bind(this)
    this.addAllToShoppingBag = this.addAllToShoppingBag.bind(this)

    this.shareALinkModalClose = this.shareALinkModalClose.bind(this)
    this.createHold = this.createHold.bind(this)
    this.showReportQcIssueSuccessDismiss = this.showReportQcIssueSuccessDismiss.bind(this);

    this.getVpoData = this.getVpoData.bind(this)
    this.factoryOnChange = this.factoryOnChange.bind(this)
    this.warehouseOnChange = this.warehouseOnChange.bind(this)
    this.toggleReadyToShip = this.toggleReadyToShip.bind(this)
    this.toggleInProgress = this.toggleInProgress.bind(this)
    this.toggleOversold = this.toggleOversold.bind(this)
    this.toggleHasVponumber = this.toggleHasVponumber.bind(this)
    this.toggleHasVpoLoadingDate = this.toggleHasVpoLoadingDate.bind(this)

    //sliders
    this.updateOnHandState = this.updateOnHandState.bind(this)
    this.updateHoldQtyState = this.updateHoldQtyState.bind(this)
    this.updateATSState = this.updateATSState.bind(this)
    this.updateFutureATSState = this.updateFutureATSState.bind(this)
    this.updatePageState = this.updatePageState.bind(this)
    this.updateHoldAgeState = this.updateHoldAgeState.bind(this)

    this.setPinTrue = this.setPinTrue.bind(this)
    this.setPinFalse = this.setPinFalse.bind(this)

    this.getContainerNumberData = this.getContainerNumberData.bind(this)

    this.multiSearchMessageSetTimeout = this.multiSearchMessageSetTimeout.bind(this)
    this.multiSearchKeyUpSetTimeout = this.multiSearchKeyUpSetTimeout.bind(this)

    this.clearCadCard = this.clearCadCard.bind(this)

    this.printOnChange = this.printOnChange.bind(this)
    this.patternOnChange = this.patternOnChange.bind(this)

    this.waitForOrgId = this.waitForOrgId.bind(this)
  }

  showReportQcIssueSuccessDismiss = () => {
    this.setState({showReportQcIssueSuccess: false});
  }

  onShowReportQcIssueSuccess = () => {
    this.setState({showReportQcIssueSuccess: true});
  }

  setPinTrue() {
    this.setState({correctPin: true})
    this.clearCadCard()
  }

  setPinFalse() {
    this.setState({correctPin: false})
    this.clearCadCard(true)
  }

  waitForOrgId() {
    const {customer} = this.context

    if (customer == null) {
      setTimeout(this.waitForOrgId, 500)
    } else {
      this.search()
      this.salesHistoryByStyleNumberGetData()
    }
  }

  componentDidMount() {
    window.latestExtraParams = null
    window.latestAPICallTime = null
    window.cadIds = {}
    window.upcsTotals = {}
    window.upcs = {}

    if (isCustomer()) {
      let customAccess = JSON.parse(sessionStorage.getItem('customAccess'))
      let quickbooksAccountName = customAccess != null ? customAccess.quickbooksAccountName : null
      this.props.actions.updateDefaultAccount([quickbooksAccountName])

      setTimeout(() => {
        this.waitForOrgId()
      }, 0)
    } else {
      this.waitForOrgId()
    }

    this.clearCadCard()

    this.resize()
    window.addEventListener('resize', this.resize)
    $(document).unbind( "keyup", arrowKeyboardClick )
    $(document).keyup(arrowKeyboardClick)
    $(window).scroll(showScrollToTop.bind(null, this.isLoading, this.showMoreCads))

    let isGuest = localStorage.getItem('isGuest') == 'TRUE'
    if (isGuest) {

      if (window.guestFilters != null) {
        this.props.actions.updateGuestFilters(window.guestFilters)
      }


    } else {
      let profile = JSON.parse(sessionStorage.getItem('user'))
      this.setState({userEmail: profile.email})
      this.getPlaylists()
    }
    window.scrollTo(0, 0)

    let role = sessionStorage.getItem('currentUserRole')
    let isAdmin = ['admin', 'superadmin', 'finance'].includes(role)
    let isSales = ['sales','sales_assistant','inventory'].includes(role)

    if (isAdmin || isSales || isProduction()) {
      this.getVpoData()
    }

    this.getContainerNumberData()

    setTimeout(() => {
      if (this.props.cadReducer.defaultAccount.length > 0) {
        this.getBestSellerData(this.props.cadReducer.defaultAccount)
      }
    }, 0)

    window.addEventListener('set-admin-pin-true', this.setPinTrue)
    window.addEventListener('set-admin-pin-false', this.setPinFalse)
    checkAdminPin()

    window.addEventListener('get-container-number-data', this.getContainerNumberData)

    window.addEventListener('openBestSellersModalEvent', this.openBestSellersModal)
  }

  getVpoData() {
    this.setState({loadingVpos: true})

    $.get(process.env.REACT_APP_API_URL + '/api/get_vpo_data/', function (result) {
      var data = JSON.parse(result.data);
      var new_data = [];

      for (var i=0; i<data.length; i++) {
        let item = data[i];
        item.index = i;

        if (item.style_number != null && item.style_number != 'FREIGHT-OCEAN' && item.style_number != 'NO ITEM' && item.style_number != 'FREIGHT-AIR' && item.style_number != '2PK SLEEPWEAR HANGER' && item.style_number.indexOf('JOKER TAG') == -1) {
          new_data.push(item);
        }
      }

      //lets organize by style number
      let factory_ids_by_style = {}, fob_rts_by_style = {}

      for (let i=0; i<new_data.length; i++) {
        let row = new_data[i]
        let styleNumber = row.style_number
        let factoryId = row.factory_id

        if (factory_ids_by_style[styleNumber] == null) {
          factory_ids_by_style[styleNumber] = [factoryId]
        } else if (factory_ids_by_style[styleNumber].indexOf(factoryId) == -1) {
          factory_ids_by_style[styleNumber].push(factoryId)
        }

        if (row.fob_rts) {
          fob_rts_by_style[styleNumber] = true
        }
      }

      window.factory_ids_by_style = factory_ids_by_style
      window.fob_rts_by_style = fob_rts_by_style
      this.setState({loadingVpos: false})
    }.bind(this));
  }

  getContainerNumberData() {
    this.setState({loadingContainerNumbers: true})

    $.get(process.env.REACT_APP_API_URL + '/api/container_number_data/', (result) => {
      window.container_number_data = result.container_number_data
      window.open_vpos_dates = result.open_vpos_dates
      window.prints = result.prints
      window.cadPrints = result.cad_prints
      setPrintOptions()
      this.clearCadCard()
      this.setState({loadingContainerNumbers: false})
    })
  }

  isLoading() {
    let result = this.state.loadingCads || this.state.loadingSalesHistoryByAccount
    return result
  }

  resize() {
    let width = $(window).width()
    let layout = width < 1200 ? 'tablet' : 'desktop'
    this.setState({layout})
  }

  search() {
    const {customer} = this.context
    let orgId = customer.id

    let extraParams = cadSearchParams(this.props)
    let now = new Date()
    let secondsSinceEpoch = Math.round(now.getTime() / 1000)

    //if we have a new search request then last, avoid extra api calls
    if (extraParams != window.latestExtraParams) {
      this.setState({loadingCads: true})

      window.latestAPICallTime = secondsSinceEpoch
      window.latestExtraParams = extraParams

      if (extraParams == '') {
        getCadsCache(orgId, this.updateCads, extraParams)
      } else {
        Utils.get('cads?time=' + secondsSinceEpoch + extraParams, (err, res) => {
          if (res.data != null && res.data.expired) {
            alert('Sorry the link has expired')
          }

          //avoiding a bug that happends when change filters fast and sometimes an earlier search returns later
          if (res.data != null && res.data.time == window.latestAPICallTime) {
            $('#inventory-report-from').text('Inventory Report from ' + res.data.inventoryReportFrom)

            var new_result = []
            var data = JSON.parse(res.data.data).data

            for (var i=0; i<data.length; i++) {
              new_result.push(data[i].attributes)
            }

            this.updateCads(new_result, extraParams)
          } else {
            console.log('API TIME IS WRONG')
          }
        })
      }

    } else {
      this.setState({
        loadingCads: false,
        sliceNum: 200,
        refreshCadCards: !this.state.refreshCadCards
      })
    }
  }

  updateCads(cads, extraParams) {
    if (extraParams == window.latestExtraParams) {
      window.cads = cads
      this.setState({
        loadingCads: false,
        sliceNum: 200
      })
    }
  }

  updateCadsSalesHistory() {
    let that = this
    updateCadsSalesHistory(this.state, this.props.cadReducer, that)
  }

  updateCadsSalesHistoryByStyleNumber() {
    let that = this
    updateCadsSalesHistoryByStyleNumber(this.state, this.props.cadReducer, that)
  }

  salesHistoryByStyleNumberGetData() {
    const {customer, user} = this.context

    //if guest and no default account return
    let isGuest = sessionStorage.getItem('isGuest') == 'TRUE'
    if (isGuest && this.props.cadReducer.defaultAccount.length == 0) {
      return
    }

    //add to a special user permissions page
    //let allowToSeeSalesOrder = window.allowToSeeSalesOrder

    if (isDesign()) { // && !allowToSeeSalesOrder
      return;
    }

    this.setState({
      loadingSalesHistoryByStyleNumber: true
    })

    //// Call api and get shipped and sales order info

    /////// OLD ///////
    // Utils.get('sales_history_by_style_number_data?is_guest=' + isGuest + '&defaultAccount=' + (this.props.cadReducer.defaultAccount == null ? null : this.props.cadReducer.defaultAccount.replace(/&/g,'%26')), (err, res) => {
    //   if (res != null) {
    //     window.salesAccountDataByStyleNumber = JSON.parse(res.data.salesAccountDataDup)
    //     window.salesOrderDataByStyleNumber = JSON.parse(res.data.salesOrderDataDup)
    //     this.setRepNames()
    //     this.updateCadsSalesHistoryByStyleNumber()
    //   }
    //
    //   this.clearCadCard()
    //
    //   this.setState({
    //     loadingSalesHistoryByStyleNumber: false,
    //     sliceNum: 200,
    //     refreshCadCards: !this.state.refreshCadCards
    //   })
    // })



    /////// NEW ///////
    getShippedCache(customer.id, user, (shippedData, salesOrderData) => {
      window.salesAccountDataByStyleNumber = shippedData
      window.salesOrderDataByStyleNumber = salesOrderData

      this.setRepNames()
      this.setAccountOptions(shippedData, salesOrderData, customer.id)
      this.updateCadsSalesHistoryByStyleNumber()
      this.clearCadCard()
      this.setState({
        loadingSalesHistoryByStyleNumber: false,
        sliceNum: 200,
        refreshCadCards: !this.state.refreshCadCards
      })
    })
  }

  setRepNames() {
    let repNames = [];
    let salesAccountKeys = Object.keys(window.salesOrderDataByStyleNumber);
    for (let i=0; i<salesAccountKeys.length; i++) {
      let salesAccountKey = salesAccountKeys[i];
      let invoices = window.salesOrderDataByStyleNumber[salesAccountKey];

      for (let j=0; j<invoices.length; j++) {
        let repName = invoices[j].rep;
        if (repName == null) {
          repName = 'UNDEFINED'
        }
        repNames.push(repName)
      }
    }

    repNames = [...new Set(repNames)];
    repNames.sort();

    this.setState({repNames});
  }

  setAccountOptions = (shippedData, salesOrderData, orgId) => {
    let salesHistoryAccounts = getAccountOptions(shippedData, salesOrderData)

    if (orgId == 1) {
      let accounts = ['ALL DDS', 'ALL ROSS', 'ALL TJ']
      accounts.reverse().forEach(account => salesHistoryAccounts.unshift({key: account, text: account, value: account}))
    }

    this.setState({salesHistoryAccounts})
  }

  componentDidUpdate() {
    clearTimeout(window.setTimeoutExpandAccordions);
    window.setTimeoutExpandAccordions = setTimeout(function() {
      //if scrolling then expand accordions if needed
      if (this.state.expandSalesHistory) {
        $('.ui.card > .content > div > .accordion.ui > .title, .ui.card > .content > div > .accordion.ui > .content').addClass('active');
      }

      $('.card > .image > img').click(imageClick)
    }.bind(this), 300)

    this.multiSearchMessageSetTimeout()

    clearTimeout(window.setTimeoutUpdateCheckboxes);
    window.setTimeoutUpdateCheckboxes = setTimeout(function() {
      if (!this.state.loading) {
        this.updateCheckboxes();
      }

    }.bind(this), 100);
  }

  multiSearchMessageSetTimeout() {
    clearTimeout(window.setTimeoutUpdateMultiSearchMessage)
    window.setTimeoutUpdateMultiSearchMessage = setTimeout(() => {
      if (this.state.multiSearch.length > 2) {
        updateMultiSearchMessage(this.state)
      }
    }, 700)
  }

  cadCard(cad, y) {
    return (
      <CadCard cad={cad} key={y} index={y} updateNumSelected={this.updateNumSelected}
        searchPONumber={this.state.searchPONumber} searchString={this.state.searchString}
        onShowReportQcIssueSuccess={this.onShowReportQcIssueSuccess}
        showReportQcIssueSuccessDismiss={this.showReportQcIssueSuccessDismiss}
        salesHistoryAccount={this.state.salesHistoryAccount} refresh={this.state.refreshCadCards}
        state={this.state} addToLineSheet={this.addToLineSheet} correctPin={this.state.correctPin}
        doingPONumberSearch={this.doingPONumberSearch} clearCadCard={this.clearCadCard}
        removeFromCart={this.removeFromCart} addToCart={this.addToCart}
        />

    )
  }

  updateNumSelected() {
    var keys = Object.keys(window.cadIds);
    if (this.state.numCadsSelected != keys.length) {
      this.setState({numCadsSelected: keys.length});
    }
  }

  viewShowHide() {
    this.setState({viewShowHideModalOpen: true});
  }

  viewShowHideModalClose() {
    this.setState({viewShowHideModalOpen: false});
  }

  onChangeHideableInput(fieldName, event, {value}) {
    this.props.actions.onChangeHideableInput(('show'+fieldName+'Index').replace(/ /g, '').replace('-', '').replace('&', ''), value);
  }

  toggleHideableCheckbox(fieldName) {
    //if turning off future units hide show want to turn off export by month
    if ((fieldName == "Future ATS" && this.props.cadReducer.showFutureATS) || (fieldName == "Future Stock" && this.props.cadReducer.showFutureStock)) {
      this.setState({exportByMonth: false});
    }

    this.props.actions.toggleHideableCheckbox(('show'+fieldName).replace(/ /g, '').replace('-', '').replace('&', ''));
  }

  selectAllHideableCheckbox(fieldNames) {
    this.props.actions.selectAllHideableCheckbox(fieldNames);
  }

  selectAllCads() {
    let cadsToFilter = window.cadsToSearch.slice(0, this.state.sliceNum)

    let cadToFilterLength = cadsToFilter.length
    var selectedLength = Object.keys(window.cadIds).length;

    var allCadsSelected = (cadToFilterLength == selectedLength);

    if (allCadsSelected) {
      window.cadIds = {};
      this.setState({numCadsSelected: 0});
    } else {
      window.cadIds = {}

      for (var i=0; i<cadsToFilter.length; i++) {
        let cadId = cadsToFilter[i].id;
        window.cadIds[cadId] = true;
      }
      this.setState({numCadsSelected: cadToFilterLength});
    }

    for (var i=0; i<cadsToFilter.length; i++) {
      let cadId = cadsToFilter[i].id;
      var elementId = 'cad-checkbox-'+cadId;
      if (document.getElementById(elementId) != null) {
        document.getElementById(elementId).checked = !allCadsSelected;
      }
    }
  }

  updateCheckboxes() {
    $('.cad-checkbox-num > input').prop('checked', false);

    var keys = Object.keys(window.cadIds);

    for (var i=0; i<keys.length; i++) {
      let cadId = keys[i];
      let myCheckbox = document.getElementById('cad-checkbox-'+cadId);
      if (myCheckbox == null)  {
        delete window.cadIds[cadId];
      } else {
        myCheckbox.checked = true;
      }
    }

    this.updateNumSelected();
  }

  compareId(a, b) {
    if (a.id > b.id) return -1;
    if (b.id > a.id) return 1;

    return 0;
  }

  sortCads(cadsToSort) {
    if (["Sort by Created", "No Image w/ Future Stock", "No Image"].includes(this.props.cadReducer.cadStatus)) {
      return cadsToSort.sort(this.compareId)
    }

    if (this.props.cadReducer.cadStatus == 'Hold') {
      if (this.state.salesHistoryAccount == '') {
        return cadsToSort.sort(this.compareHoldSalesHistoryDatesByStyleNumber)
      } else {
        return cadsToSort.sort(this.compareHoldSalesHistoryDates)
      }
    }

    if (this.props.cadReducer.cadStatus == 'PO') {
      if (this.state.salesHistoryAccount == '') {
        return cadsToSort.sort(this.compareSalesOrderDatesByStyleNumber)
      } else {
        return cadsToSort.sort(this.compareSalesOrderDates)
      }
    }

    if (this.state.multiSearch.length > 2) {
      return cadsToSort.sort(compareMultiSearchIndex)
    }

    if (this.props.cadReducer.futureAtsMonths.length > 0) {
      return cadsToSort.sort(this.compareFutureAtsMonths)
    }

    if (this.props.cadReducer.cadStatus == 'Future ATS') {
      return cadsToSort.sort(this.compareFutureAts)
    }

    if (['Pending PI', 'FACTORY-RTS'].indexOf(this.props.cadReducer.cadStatus) > -1) {
      return cadsToSort.sort(this.compareFutureAtsPendingPI)
    }

    if ((this.state.salesHistoryAccount != '' || this.props.cadReducer.defaultAccount.length > 0) && this.props.cadReducer.cadStatus == 'Best Sellers') {
      return cadsToSort.sort(this.compareBestSellerDates);

    } else if ((this.state.salesHistoryAccount != '' || this.props.cadReducer.defaultAccount.length > 0) && this.props.cadReducer.cadStatus == 'Poor Sellers') {
      return cadsToSort.sort(this.comparePoorSellerDates)

    } else {
      return cadsToSort
    }
  }

  searchFilter(cads) {
    // let usingSalesOrderOrHoldFilter = this.props.cadReducer.cadStatus == "Shipped" || this.props.cadReducer.cadStatus == "Sales Order" || this.props.cadReducer.cadStatus == "Hold" || this.props.cadReducer.cadStatus == "Worksheet" || this.props.cadReducer.cadStatus == "Best Sellers" || this.props.cadReducer.cadStatus == "Poor Sellers" || this.props.cadReducer.cadStatus == "QC" || this.props.cadReducer.cadStatus == "ETA / Loading Date" || this.props.cadReducer.cadStatus == "No ETA / Loading Date" || this.props.cadReducer.cadStatus == "Deals"  || this.props.cadReducer.cadStatus == "Not Purchased" || this.props.cadReducer.cadStatus == "Error Syncing" || this.props.cadReducer.cadStatus ==  "Error Syncing and Reviewed" || this.props.cadReducer.cadStatus == "Immediate" || this.props.cadReducer.cadStatus == "No Image" || this.props.cadReducer.cadStatus == "Image"  || this.props.cadReducer.cadStatus == "No Sizes" || this.props.cadReducer.cadStatus == "Sizes" || this.props.cadReducer.cadStatus == "ATS" || this.props.cadReducer.cadStatus == "ATS or Future ATS" || this.props.cadReducer.margins.length > 0;

    // if (this.state.searchString2 == '' && this.state.multiSearch2 == '' && this.state.maxPage === 800 && this.state.minPage === 0 && this.state.minUnits === 0 && this.state.minFutureUnits === 0 && this.state.maxUnits === 0 && this.state.maxFutureUnits === 0 && this.state.page === -100 && this.props.cadReducer.futureAtsMonths.length == 0 && !usingSalesOrderOrHoldFilter) {
    //   window.cadsLength = cads.length;
    //   return cads;
    // } else {
      let str = this.state.searchString.toLowerCase();
      let str_nospaces = str.replace(/\s/g, '');

      // window.multiSearchIndex = {};
      // window.multiSearchIndexTotals = {};

      let newCads = cads.filter(cad => cadFilter(cad, str, str_nospaces, this.state, this.props))
      window.cadsLength = newCads.length
      return newCads     //OLD: cad.style_number.toLowerCase().startsWith(str));
    // }
  }

  clearFilters() {
    this.props.actions.clearFilters()
    setTimeout(() => { window.location.reload() }, 100)
  }

  cadStatusOnChange(event, {value}) {
    if (value.indexOf('Filter by') > -1 || value == 'Sort') {
      return
    }

    this.props.actions.updateCadStatus(value);

    //get rid of reps selection if status isn't shipped/sales orders/etc
    let useFilteredData = ["PO", "Hold", "Shipped", "Worksheet"].indexOf(value) > -1;
    if (!useFilteredData) {
      this.setState({reps: []})
    }

    //only turn off ready to ship if not OSO
    if (["PO", "Hold", "Worksheet"].indexOf(value) == -1) {
      this.setState({readyToShip: false, oversold: false, hasVponumber: false, hasVpoLoadingDate: false})
    }

    if (value == "Shipped") {
      this.setState({inProgress: false})
    }

    if (value == 'ATS or Future ATS') {
      window.minOnHand = 1
      window.maxOnHand = 11000
      window.minAts = 1
      window.maxAts = 11000
      window.minFutureAts = isCustomer() ? 0 : -3100
      window.maxFutureAts = 11000
      window.minPage = 0    //later change to 1 maybe?
      window.maxPage = 800
      window.minHoldAge = 0    //later change to 1 maybe
      window.maxHoldAge = window.defaultMaxHoldAge
      window.minHoldQty = 1
      window.maxHoldQty = 11000
      $('#on-hand-min').text('0')
      $('#on-hand-max').text('11000+')
      $('#hold-qty-min').text('0')
      $('#hold-qty-max').text('11000+')
      $('#ats-min').text('0')
      $('#ats-max').text('11000+')
      $('#future-ats-min').text('-3000 or less')

      $('#future-ats-max').text('11000+')

      $('#page-min').text(window.minPage)
      $('#page-max').text(window.maxPage+'+')

      $('#hold-age-min').text(window.minHoldAge+' days')
      $('#hold-age-max').text(window.maxHoldAge+' days')
      this.setState({
        minOnHand: 0,
        maxOnHand: 0,
        minHoldQty: 0,
        maxHoldQty: 0,
        minUnits: 0,
        maxUnits: 0,
        minFutureUnits: isCustomer() ? 0 : -3000,
        maxFutureUnits: 0,
        minPage: 0,
        maxPage: 800, //treat as 800+
        minHoldAge: 0,
        maxHoldAge: window.defaultMaxHoldAge, //treat as 800+
        minHoldQty: 0,
        maxHoldQty: 0,
      })
    }

    setTimeout(this.search, 100)
  }

  handleSearchChange = (e, {value}) =>
  {
    if (this.state.searchContainerNumber) {
      this.setState({hideContainerNumber: true})
    }

    clearTimeout(window.searchStringTimeout)
    window.searchStringTimeout = setTimeout(() => {
      let newState = {searchString: value}

      if (this.state.searchContainerNumber) {
        newState['hideContainerNumber'] = false
      }

      this.setState(newState)

      this.saveLastSearch(value);

      if (this.state.searchPONumber && value.length > 2) {
        this.updateMissingSearchPONumber()
      }

      if (!this.state.searchContainerNumber && !this.state.searchPONumber) {
        searchToUnarchive(value)
      }

    }, 1500)
  }

  saveLastSearch(searchString) {
    this.props.actions.updateLast5Searches(searchString)
  }

  last5Searches(e, {value}) {
    this.handleSearchChange(null, {value})

    $('#cads-search-input > div > input').val(value)
  }

  brandOnChange(event, {value}) {
    this.props.actions.updateBrand(value)
    setTimeout(this.search, 100)
  }

  divisionOnChange (event, {value}) {
    this.setState({showSimilarStyleNumber: ''})
    this.props.actions.updateDivision(value)
    setTimeout(this.search, 100)
  }

  categoryOnChange (event, {value}) {
    this.setState({showSimilarStyleNumber: ''})
    this.props.actions.updateCategory(value)

    //check for applicable subcategories
    if (value.length == 1) {
      const {customer} = this.context
      let firstValue = value[0]

      let category = customer.categories.filter((x) => {return x.name == firstValue})[0]

      if (category != null && category.subcategories != null && category.subcategories.length > 0) {
        let applicableSubcategories = category.subcategories
          .map(x => customer.subcategories.find(el => el.id == x))
          .map((x) => {return x.name})

        let value2 = this.props.cadReducer.subCategory.filter((y) => {
          return applicableSubcategories.indexOf(y) > -1
        })

        this.props.actions.updateSubCategory(value2)
      }
    }

    setTimeout(this.search, 100)
  }

  subCategoryOnChange (event, {value}) {
    this.setState({showSimilarStyleNumber: ''})
    this.props.actions.updateSubCategory(value)
    setTimeout(this.search, 100)
  }

  showATSSliderHandleClick() {
    var newShowATSSlider = !this.state.showATSSlider;
    this.setState({showATSSlider: newShowATSSlider});
  }

  showExtraFiltersHandleClick() {
    var newShowExtraFilters = !this.state.showExtraFilters;
    this.setState({showExtraFilters: newShowExtraFilters})
  }

  defaultAccountOnChange(event, {value}) {
    this.props.actions.updateDefaultAccount(value)
    this.getBestSellerData(value)
  }

  getBestSellerData(defaultAccount) {
    this.setState({loadingBestSellerData: true})
    getBestSellerData(defaultAccount, () => {
      this.setState({loadingBestSellerData: false})
      this.clearCadCard()
    })
  }

  packsOnChange (event, {value}) {
    this.setState({showSimilarStyleNumber: ''});
    this.props.actions.updatePacks(value);
    setTimeout(this.search, 100)
  }

  fabricationOnChange (event, {value}) {
    this.setState({showSimilarStyleNumber: ''});
    this.props.actions.updateFabrication(value);
    setTimeout(this.search, 100)
  }
  colorOnChange (event, {value}) {
    this.props.actions.updateColor(value);
    setTimeout(this.search, 100)
  }

  seasonOnChange (event, {value}) {
    this.props.actions.updateSeason(value);
    setTimeout(this.search, 100)
  }

  updateFutureATSState(minFutureUnits, maxFutureUnits) {
    this.setState({minFutureUnits, maxFutureUnits})
  }

  updatePageState(minPage, maxPage) {
    this.setState({minPage, maxPage})
  }

  updateMaxHoldAge(dateFrom) {
    let differenceInDays = getDifferenceInDays(dateFrom)
    window.maxHoldAge = differenceInDays
    $('#hold-age-max').text(differenceInDays)
    this.setState({maxHoldAge: differenceInDays})
  }

  updateMinHoldAge(dateTo) {
    let differenceInDays = getDifferenceInDays(dateTo)
    window.minHoldAge = differenceInDays
    $('#hold-age-min').text(differenceInDays)
    this.setState({minHoldAge: differenceInDays})
  }

  updateHoldAgeState(minHoldAge, maxHoldAge) {
    this.setState({minHoldAge, maxHoldAge})

    let date = new Date();
    date.setDate(date.getDate() - maxHoldAge);
    let value = (date.getMonth()+1).toString().padStart(2, '0') + '/' + date.getDate().toString().padStart(2, '0') + '/' + date.getFullYear();

    this.dateFromOnChange (null, {value}, false);


    if (minHoldAge == 0) {
      value = window.defaultDateTo
    } else {
      let date = new Date();
      date.setDate(date.getDate() - minHoldAge);
      value = (date.getMonth()+1).toString().padStart(2, '0') + '/' + date.getDate().toString().padStart(2, '0') + '/' + date.getFullYear();
    }

    this.dateToOnChange (null, {value}, false);
  }

  dateFromOnChange (event, {value}, updateHoldSlider = true) {
    this.props.actions.updateDateFrom(value);

    setTimeout(function() {
      this.setState({
        loading: true,
        cadsSalesHistory: []
      });
      if (this.state.salesHistoryAccount != '') {
        this.updateCadsSalesHistory();
      } else {
        this.updateCadsSalesHistoryByStyleNumber();
      }

    }.bind(this), 100);

    if (this.props.cadReducer.cadStatus == 'Hold' && updateHoldSlider) {
      this.updateMaxHoldAge(value)
    }
  }

  dateToOnChange (event, {value}, updateHoldSlider = true) {
    this.props.actions.updateDateTo(value);

    setTimeout(function() {
      this.setState({
        loading: true,
        cadsSalesHistory: []
      });
      if (this.state.salesHistoryAccount != '') {
        this.updateCadsSalesHistory();
      } else {
        this.updateCadsSalesHistoryByStyleNumber();
      }
    }.bind(this), 100);

    if (this.props.cadReducer.cadStatus == 'Hold' && updateHoldSlider) {
      this.updateMinHoldAge(value)
    }
  }


  exportPdf(event, {value}) {
    var keys = Object.keys(window.cadIds);

    if (['PDF','Save as line sheet','UPC'].includes(value) && keys.length == 0) {
      alert('Select at least 1 CAD')
    } else if (value == "PDF" && keys.length > 200) {
      alert('You have selected ' + keys.length + ' Cads. Limit is 200 Cads!')
    } else {
      if (value == "Excel") {
        if (this.state.searchPONumber) {
          if (window.searchPONumberAccount == null) {
            alert('Please enter a valid PO number!')
          } else {
            let ponumberSearch = window.searchPoNumber.replace(/\//g, '%2F');
            var excelUrl = process.env.REACT_APP_API_URL + '/api/create_sales_order_excel/' + ponumberSearch + '?account_name=' + (window.searchPONumberAccount == null ? null : window.searchPONumberAccount.replace(/&/g,'%26'))
            window.location = excelUrl;
          }
        } else {
          if (multipleBrandsSelected()) {
            this.setState({multipleBrandsSelected: true})
          }

          $('#show-excel-modal').click();

          if (window.maxValue != null) {
            setTimeout(() => {
              $('#hide-units-under').val(window.maxValue)
            },500)
          }
        }
      } else if (value == "PDF") {

        $('#show-pdf-modal').click();

      } else if (value == "Save as line sheet") {
        let playlist_name = window.prompt('Enter new line sheet title')
        this.saveAsNewLinesheet(playlist_name)
      } else if (value == 'UPC') {
        getUpcExcel(keys)
      }
    }
  }

  setCadsPerPage(perPage) {
    this.setState({cadsPerPage: perPage});
  }

  downloadPdf() {
    let orgId = this.context.customer.id
    let coverPageTitle = $('#cover-page-title').val()
    let coverPageSubTitle = $('#cover-page-sub-title').val()
    let pdfUrl = getPdfUrl(this.state, this.props, this.state.cadsPerPage, coverPageTitle, coverPageSubTitle, orgId)

    //TODOmake get request and open the temp public url in new window

    var myWindow = window.open(pdfUrl, "", "width=900,height=700")
  }

  downloadExcel() {
    let orgId = this.context.customer.id

    let excelUrl = getExcelUrl(this.state, this.props, orgId)

    if (excelUrl != null) {
      window.location = excelUrl
    }

    //var myWindow = window.open(excelUrl, "", "width=900,height=700");
  }

  emailPdf() {
    let orgId = this.context.customer.id
    let coverPageTitle = $('#cover-page-title').val()
    let coverPageSubTitle = $('#cover-page-sub-title').val()
    let mailtoUrl = getPdfUrl(this.state, this.props, this.state.cadsPerPage, coverPageTitle, coverPageSubTitle, orgId).replace('download_cads_pdf','mailto_cads_pdf')

    $.post( mailtoUrl, {},
      function(data){
        var emailSub = "CAD PDF";
        var emailBody = "Here is the link to the CAD PDF   " + data.download_url;
        window.location.href = 'mailto:?subject='+emailSub+'&body='+emailBody;
      });
  }

  toggleExportByBrand() {
    let value = !this.state.exportByBrand
    let newState = {exportByBrand: value}

    if (value) {
      newState['exportNoImages'] = false
      newState['combineCads'] = false
      newState['exportByMonth'] = false
    }

    this.setState(newState)
  }

  toggleExportByMonth() {
    let value = !this.state.exportByMonth
    let newState = {exportByMonth: value}

    if (value) {
      newState['exportNoImages'] = false
      newState['combineCads'] = false
      newState['exportByBrand'] = false

      if (!this.props.cadReducer.showFutureATS && !this.props.cadReducer.showFutureStock) {
        this.props.actions.toggleHideableCheckbox('showFutureATS')
      }
    }

    this.setState(newState)
  }

  toggleExportNoImages() {
    let value = !this.state.exportNoImages
    let newState = {exportNoImages: value}

    if (value) {
      newState['exportByMonth'] = false
      newState['exportByBrand'] = false
    }

    this.setState(newState)
  }

  toggleCombineCads() {
    let value = !this.state.combineCads
    let newState = {combineCads: value}

    if (value) {
      newState['exportByMonth'] = false
      newState['exportByBrand'] = false
      newState['exportNoImages'] = false
      newState['exportMargins'] = false
      newState['hideUnitsUnder'] = false
    }

    this.setState(newState)
  }

  toggleExportMargins() {
    this.setState({exportMargins: !this.state.exportMargins, combineCads: false})
  }

  toggleHideUnitsUnder() {
    window.maxValue = null
    let newValue = !this.state.hideUnitsUnder
    this.setState({hideUnitsUnder: newValue, combineCads: false})
  }

  hideUnitsUnderOnChange(e) {
    window.maxValue = e.target.value
  }

  expandSalesHistory() {
    this.setState({expandSalesHistory: true});
    $('.ui.card > .content > div > .accordion.ui > .title, .ui.card > .content > div > .accordion.ui > .content').addClass('active');
  }

  compressSalesHistory() {
    this.setState({expandSalesHistory: false});
    $('.ui.card > .content > div > .accordion.ui > .title, .ui.card > .content > div > .accordion.ui > .content').removeClass('active');
  }

  showMoreCads() {
    var sliceNum = this.state.sliceNum;

    if (sliceNum < window.cadsLength) {
      sliceNum += 200;
      this.setState({sliceNum: sliceNum});
    }
  }


  bulkChangeCads() {
    var keys = Object.keys(window.cadIds);
    if (keys.length == 0) {
      alert('Select at least 1 CAD');
      return;
    } else if (keys.length > 500) {
      alert('You have selected ' + keys.length + ' Cads. Reducing selection to first 500!')

      //select first 500 cads
      //update checkboxes

      let cadsToFilter = window.cadsToSearch.slice(0)

      cadsToFilter = cadsToFilter.slice(0, 500);

      if (cadsToFilter.length == 0) {
        alert("Can't find any CADs to bulk edit");
        return;
      }

      window.cadIds = {};
      for (var i=0; i<cadsToFilter.length; i++) {
        let cadId = cadsToFilter[i].id;
        window.cadIds[cadId] = true;
      }

      this.setState({numCadsSelected: 500});

      for (var i=0; i<cadsToFilter.length; i++) {
        let cadId = cadsToFilter[i].id;
        var elementId = 'cad-checkbox-'+cadId;
        if (document.getElementById(elementId) != null) {
          document.getElementById(elementId).checked = true;
        }
      }
    }

    //also clear bulk values
    let clearBulkValues = new Event('clearBulkValues')
    window.dispatchEvent(clearBulkValues)
    this.setState({bulkModalOpen: true});
  }

  bulkModalClose() {
    this.setState({bulkModalOpen: false});
  }

  poorSellerAccountOnChange(event, {value}) {
    this.setState({poorSellerAccount: value});
  }

  averageSellerAccountOnChange(event, {value}) {
    this.setState({averageSellerAccount: value});
  }

  bestSellerAccountOnChange(event, {value}) {
    this.setState({bestSellerAccount: value});
  }

  markAsPoorSeller() {
    var keys = Object.keys(window.cadIds);
    if (keys.length == 0) {
      alert('Select at least 1 CAD')
    } else {
      $('#mark-as-poor-seller-date').val(window.todaysDate);
      this.setState({poorSellerAccount: '', bestSellerSuccessVisible: false, poorSellerSuccessVisible: false, averageSellerSuccessVisible: false, poorSellerModalOpen: true});
    }
  }
  markAsAverageSeller() {
    var keys = Object.keys(window.cadIds);
    if (keys.length == 0) {
      alert('Select at least 1 CAD')
    } else {
      $('#mark-as-average-seller-date').val(window.todaysDate);
      this.setState({averageSellerAccount: '', bestSellerSuccessVisible: false, poorSellerSuccessVisible: false, averageSellerSuccessVisible: false, averageSellerModalOpen: true});
    }
  }

  markAsBestSeller() {
    var keys = Object.keys(window.cadIds);
    if (keys.length == 0) {
      alert('Select at least 1 CAD')
    } else {
      $('#mark-as-best-seller-date').val(window.todaysDate);
      this.setState({bestSellerAccount: '', bestSellerSuccessVisible: false, poorSellerSuccessVisible: false, averageSellerSuccessVisible: false, bestSellerModalOpen: true});
    }
  }

  bestSellerSuccessHandleDismiss() {
    this.setState({bestSellerSuccessVisible: false});
  }

  averageSellerSuccessHandleDismiss() {
    this.setState({averageSellerSuccessVisible: false});
  }

  poorSellerSuccessHandleDismiss() {
    this.setState({poorSellerSuccessVisible: false});
  }

  mySetState = x => this.setState(x)

  saveBestPoorSeller(bestOrPoor) {
    saveBestSeller(bestOrPoor, this.state, this.mySetState, this.clearCadCard, this.props.cadReducer)
  }

  bestSellerModalClose() {
    this.setState({bestSellerModalOpen: false, poorSellerModalOpen: false, averageSellerModalOpen: false});
  }

  searchForOnChange(event, {value}) {
    let searchPONumber = (value == 'Sales Order')
    let searchContainerNumber = (value == 'Container #')
    let newState = {searchPONumber, searchContainerNumber}

    if (searchPONumber) {
      if (!this.props.cadReducer.showShipped) {
        this.props.actions.toggleHideableCheckbox('showShipped');
      }

      if (!this.props.cadReducer.showPO) {
        this.props.actions.toggleHideableCheckbox('showPO');
      }

      if (!this.props.cadReducer.showHold) {
        this.props.actions.toggleHideableCheckbox('showHold');
      }

      if (!this.props.cadReducer.showWorksheet) {
        this.props.actions.toggleHideableCheckbox('showWorksheet');
      }
    }

    if (searchContainerNumber) {
      if (!this.props.cadReducer.showContainerNumber) {
        this.props.actions.toggleHideableCheckbox('showContainerNumber')
      }
    }

    if (searchPONumber || searchContainerNumber) {
      newState.salesHistoryAccount = ''
    }

    this.clearCadCard()

    this.setState(newState)

    if (searchPONumber && this.state.searchString.length > 2) {
      this.updateMissingSearchPONumber()
    }

    if (this.state.showMultiSearch) {
      this.multiSearchMessageSetTimeout()
    }
  }

  updateMissingSearchPONumber() {
    let ponumberSearch = this.state.searchString.trimUpcase()
    let missingStyleNumbers = []
    let styleNumbers = window.cads.map(i => i.style_number.flatUpcase())
    let cadIds = window.cads.map(i => i.id)
    let keys = Object.keys(window.salesOrderDataByStyleNumber)
    let keys2 = Object.keys(window.salesAccountDataByStyleNumber)

    let accountName, refNumber, searchPoNumber
    let orderType = "PO"
    let startShip = []

    keys.forEach(key => {
      let orders = window.salesOrderDataByStyleNumber[key];

      if (accountName != null) {
        orders = orders.filter(x => x.account_name == accountName)
      }

      let ponumbers = []
      let refnumbers = []
      orders.forEach(order => {
        if (order.ponumber != null) {
          let ponumber = order.ponumber.trimUpcase()
          ponumbers.push(ponumber)
          if (order.refnumber != null) {
            let refnumber = order.refnumber.trimUpcase()
            refnumbers.push(refnumber)
          }
        }
      })

      let foundMatch = (ponumbers.indexOf(ponumberSearch) > -1) || (refnumbers.indexOf(ponumberSearch) > -1)

      if (foundMatch) {
        let order = orders.find(el => {
          let ponumber = el.ponumber.trimUpcase()
          let refnumber = (el.refnumber == null ? null : el.refnumber.trimUpcase())
          return (ponumber === ponumberSearch || refnumber === ponumberSearch)
        })

        //get account name    and order type     from first match
        if (accountName == null) {
          accountName = order.account_name
          refNumber = (order.refnumber == null ? null : order.refnumber.trimUpcase())
          searchPoNumber = order.ponumber.trimUpcase()

          if (!!order.hold) { orderType = "Hold"; }
          if (!!order.worksheet) { orderType = "Worksheet"; }
        }

        startShip.push(order.date);
      }

      if (foundMatch && !(styleNumbers.includes(key) || cadIds.includes(key))) {
        missingStyleNumbers.push(key)
      }
    })

    //check shipped data if didn't find anything
    if (accountName == null) {
      keys2.forEach(key => {
        let orders = window.salesAccountDataByStyleNumber[key]

        if (accountName != null) {
          orders = orders.filter(x => x.account_name == accountName)
        }

        let ponumbers = []
        orders.forEach(order => {
          if (order.ponumber != null) {
            let ponumber = order.ponumber.trimUpcase()
            ponumbers.push(ponumber)
          }
        })

        let foundMatch = ponumbers.indexOf(ponumberSearch) > -1

        if (foundMatch) {
          let order = orders.find(el => {
            let ponumber = el.ponumber.trimUpcase()
            return ponumber === ponumberSearch
          })

          //get account name    and order type     from first match
          if (accountName == null) {
            accountName = order.account_name;
            refNumber = (order.refnumber == null ? null : order.refnumber.trimUpcase())
            searchPoNumber = order.ponumber.trimUpcase()

            if (!!order.in_progress) {
              orderType = "In-Progress"
            } else {
              orderType = "Shipped"
            }
          }

          startShip.push(order.date);
        }

        if (foundMatch && !(styleNumbers.includes(key) || cadIds.includes(key))) {
          missingStyleNumbers.push(key);
        }
      })
    }

    window.missingStyleNumbersSearchPONumber = missingStyleNumbers;
    window.searchPONumberAccount = accountName;
    window.searchPONumberOrderType = orderType;
    window.searchRefNumber = refNumber
    window.searchPoNumber = searchPoNumber

    startShip.sort();
    let earliestStartShip = startShip[0];
    let latestStartShip = startShip.reverse()[0];
    if (earliestStartShip == latestStartShip) {
      latestStartShip = null;
    }
    if (earliestStartShip != null) {
      let earliestStartShipParts = earliestStartShip.split('-');
      window.searchPONumberStartShip = earliestStartShipParts[1] + '/' + earliestStartShipParts[2] + '/' + earliestStartShipParts[0];
    } else {
      window.searchPONumberStartShip = undefined;
    }

    if (latestStartShip != null) {
      let latestStartShipParts = latestStartShip.split('-');
      window.searchPONumberLatestStartShip = ' — ' + latestStartShipParts[1] + '/' + latestStartShipParts[2] + '/' + latestStartShipParts[0];
    } else {
      window.searchPONumberLatestStartShip = undefined;
    }

    this.clearCadCard()

    // this.setState({loadingSearchPONumber: false});
    this.forceUpdate()//might not need
  }

  multiSearchKeyUpSetTimeout() {
    var value = $('#multi-search-textarea').val()

    if (this.state.searchContainerNumber) {
      this.setState({hideContainerNumber: true})
    }

    clearTimeout(window.multiSearchKeyUp)
    window.multiSearchKeyUp = setTimeout(() => {
      let newState = {multiSearch: value}

      if (this.state.searchContainerNumber) {
        newState['hideContainerNumber'] = false
      }

      this.setState(newState)
      this.updateMultiSearchStyleNumbers()
    }, 300)
  }

  toggleMultiSearch() {
    let newShowMultiSearch = !this.state.showMultiSearch

    this.setState({showMultiSearch: newShowMultiSearch, searchString: '', multiSearch: '', searchPONumber: false})

    if (newShowMultiSearch) {
      setTimeout(() => {
        $('#multi-search-textarea').keyup(this.multiSearchKeyUpSetTimeout)
      }, 0)
    }

    this.clearCadCard()
  }

  updateMultiSearchStyleNumbers() {
    setTimeout(() => {
      if (this.state.multiSearch.length > 2) {
        var styleNumbers = this.state.multiSearch.split("\n");
        var styleNumbers2 = [];
        var zwbs_regex = /\p{Space}/gi;
        for (var i=0; i<styleNumbers.length; i++) {
          var styleNumber = styleNumbers[i].replace(zwbs_regex, ' ').trim().toLowerCase();
          if (styleNumber != '' && styleNumbers2.indexOf(styleNumber) == -1) {
            styleNumbers2.push(styleNumber);
          }
        }

        window.multiSearchIndex = {}
        window.multiSearchIndexTotals = {}

        this.setState({multiSearchStyleNumbers: styleNumbers2});

        searchToUnarchiveBulk(styleNumbers2)
      }
    },0)
  }

  doingPONumberSearch() {
    return this.state.searchPONumber && this.state.searchString.length > 2
  }

  marginsOnChange (event, {value}) {
    this.setState({showSimilarStyleNumber: ''}); //why am i doing this?
    this.props.actions.updateMargins(value)
    setTimeout(() => {this.search();}, 100)
  }

  futureAtsMonthsOnChange (event, {value}) {
    this.props.actions.updateFutureAtsMonths(value);
  }

  factoryOnChange (event, {value}) {
    this.props.actions.updateFactory(value)
  }

  warehouseOnChange(event, {value}) {
    this.props.actions.updateWarehouse(value)
    if (!this.props.cadReducer.showWarehouse) { this.props.actions.toggleHideableCheckbox('showWarehouse') }
    this.clearCadCard()
  }

  repOnChange(event, {value}) {
    this.setState({reps: value})

    setTimeout(function() {
      this.setState({
        loading: true,
        cadsSalesHistory: []
      });
      if (this.state.salesHistoryAccount != '') {
        this.updateCadsSalesHistory();
      } else {
        this.updateCadsSalesHistoryByStyleNumber();
      }

    }.bind(this), 100);
  }

  playlistRow(playlist, index) {
    return(
      <Table.Row key={index}>
        <Table.Cell>{playlist.playlist_name}</Table.Cell>
        <Table.Cell style={{width: '30%', textAlign: 'right'}}>
          {this.props.cadReducer.currentPlaylistId == playlist.id ? (
            <Button style={{fontWeight: 'bold'}} color='orange' basic onClick={this.closePlaylistGreyBar}>Viewing</Button>
          ) : (
            <Button basic onClick={this.goToPlaylistId.bind(null, playlist)}>View</Button>
          )}

        </Table.Cell>
      </Table.Row>
    );
  }

  addToPlaylistRow(playlist, index) {
    let alreadyAdded = playlist.cad_ids.indexOf(window.addToPlaylistCadId) > -1;
    return(
      <Table.Row key={index}>
        <Table.Cell>{playlist.playlist_name}</Table.Cell>
        <Table.Cell style={{width: '30%', textAlign: 'right'}}>
          {alreadyAdded ? (
            <Button basic style={{fontWeight: 'bold'}} color='orange' onClick={this.removeFromPlaylist.bind(null, playlist)}>Added</Button>
          ) : (
            <Button basic onClick={this.addToPlaylist.bind(null, playlist)}>Add to line sheet</Button>
          )}
        </Table.Cell>
      </Table.Row>
    );
  }

  removeFromPlaylist(playlist) {
    let profile = JSON.parse(sessionStorage.getItem('user'));

    $.ajax({
      type: "POST",
      url: process.env.REACT_APP_API_URL + '/api/remove_from_playlist',
      data: {
        playlist_id: playlist.id,
        cad_id: window.addToPlaylistCadId,
        user_id: profile.auth0Uuid
      },
      success: function (data) {
        this.getPlaylists();
      }.bind(this),
      error: function (error) {
          // handle error
          alert('There was an error when trying to remove the cad from the playlist.');
      },
    });
  }

  addToPlaylist(playlist) {
    let profile = JSON.parse(sessionStorage.getItem('user'));

    $.ajax({
      type: "POST",
      url: process.env.REACT_APP_API_URL + '/api/add_to_playlist',
      data: {
        playlist_id: playlist.id,
        cad_id: window.addToPlaylistCadId,
        user_id: profile.auth0Uuid
      },
      success: function (data) {
        this.getPlaylists();
      }.bind(this),
      error: function (error) {
          // handle error
          alert('There was an error when trying to add the cad to the playlist.')
      },
    });
  }

  //will also need when open the add to playlist
  getPlaylists() {
    let profile = JSON.parse(sessionStorage.getItem('user'));

    $.get(process.env.REACT_APP_API_URL + '/api/playlists?user_id=' + profile.auth0Uuid, function (result) {
      //console.log(result);
      this.setState({playlists: result});
    }.bind(this));
  }

  viewPlaylists() {
    this.getPlaylists();
    this.setState({viewPlaylistsModalOpen: true});
  }

  viewPlaylistsModalClose() {
    this.setState({viewPlaylistsModalOpen: false});
  }

  closePlaylistGreyBar() {
    this.props.actions.updatePlaylist(null, '');

    setTimeout(function() {
      this.search();
    }.bind(this), 0);
  }

  addToPlaylistModalClose() {
    this.setState({addToPlaylistModalOpen: false});
  }


  goToNewPlaylist() {
    this.props.actions.updatePlaylist(window.newPlaylistId, window.newPlaylistName);
    this.addToPlaylistModalClose();

    setTimeout(function() {
      this.search();
    }.bind(this), 0);
  }

  goToPlaylistId(playlist) {
    this.props.actions.updatePlaylist(playlist.id, playlist.playlist_name);
    this.viewPlaylistsModalClose();

    setTimeout(function() {
      this.search();
    }.bind(this), 0);
  }

  submitNewPlaylist() {
    let playlist_name = $('#my-new-playlist-modal-input').val();

    let nameAlreadyTaken = (this.state.playlists.filter(playlist => playlist.playlist_name.toLowerCase() == playlist_name.toLowerCase()).length > 0);

    if (nameAlreadyTaken) {
      alert('That playlist name is already taken.')
    } else {

      let profile = JSON.parse(sessionStorage.getItem('user'));
      let user_name = (profile.name != null ? profile.name : profile.email)

      window.playlist_name = playlist_name;

      $.ajax({
        type: "POST",
        url: process.env.REACT_APP_API_URL + '/api/save_new_playlist',
        data: {
          playlist_name: playlist_name,
          cad_id: window.addToPlaylistCadId,
          user_name: user_name,
          user_id: profile.auth0Uuid
        },
        success: function (data) {
          //console.log(data);
          this.setState({showNewPlaylistSuccess: true});
          window.newPlaylistId = data.playlist_id;
          window.newPlaylistName = playlist_name;
          this.getPlaylists();

        }.bind(this),
        error: function (error) {
            // handle error
            alert('There was an error when trying to save the new line sheet.')

        },
      });
    }
  }

  saveAsNewLinesheet(playlist_name) {
    let nameAlreadyTaken = (this.state.playlists.filter(playlist => playlist.playlist_name.toLowerCase() == playlist_name.toLowerCase()).length > 0);

    if (nameAlreadyTaken) {
      alert('That playlist name is already taken.')
    } else {

      let profile = JSON.parse(sessionStorage.getItem('user'));
      let user_name = (profile.name != null ? profile.name : profile.email)

      window.playlist_name = playlist_name;

      $.ajax({
        type: "POST",
        url: process.env.REACT_APP_API_URL + '/api/save_new_playlist',
        data: {
          playlist_name: playlist_name,
          cad_ids: Object.keys(window.cadIds),
          user_name: user_name,
          user_id: profile.auth0Uuid
        },
        success: function (data) {
          //console.log(data);
          this.setState({showNewPlaylistSuccess: true});
          window.newPlaylistId = data.playlist_id;
          window.newPlaylistName = playlist_name;
          this.getPlaylists();

        }.bind(this),
        error: function (error) {
            // handle error
            alert('There was an error when trying to save the new line sheet.')

        },
      });
    }
  }

  addToLineSheet() {
    this.setState({addToPlaylistModalOpen: true, showNewPlaylistSuccess: false});
  }

  sub2OnChange(event, {value}) {
    this.props.actions.updateSub2(value)
    setTimeout(this.search, 100)
  }

  shareLink() {
    let brandDivisionCategorySelected = this.props.cadReducer.brand.length > 0 && this.props.cadReducer.division.length > 0 && this.props.cadReducer.category.length > 0;

    if (!brandDivisionCategorySelected) {
      alert('Link CANNOT be shared UNLESS have Brand - Division - Category selected!');
    } else {
      let profile = JSON.parse(sessionStorage.getItem('user'))
      let url = process.env.REACT_APP_API_URL + '/api/create_cad_link';

      let maxAts = this.state.maxUnits == 11000 ? 0 : this.state.maxUnits;
      let maxFutureAts = this.state.maxFutureUnits == 11000 ? 0 : this.state.maxFutureUnits;

      let orgId = this.context.customer.id

      $.post( url,
        {data: this.props.cadReducer, user_id: profile.auth0Uuid, user_email: profile.email,
          minAts: this.state.minUnits, maxAts: maxAts, minFutureAts: this.state.minFutureUnits,
          maxFutureAts: maxFutureAts, sales_history_account: this.state.salesHistoryAccount,
          org_id: orgId},
        (data) => {
          var emailSub = this.props.cadReducer.brand.join(', ') + ' - ' + this.props.cadReducer.category.join(', ');//Tahari Sleepwear/asdf basic initimes - Aeropostale Sleepware/asdf
          var shareLink = process.env.REACT_APP_URL + "/guest?t=" + data.token;
          var emailBody = "Here is the link to view the CADs   " + shareLink;

          window.emailSub = emailSub
          window.emailBody = emailBody
          this.setState({shareALinkModalOpen: true})
        }
      )
    }
  }

  addToCart(cadId) {
    this.props.actions.addToShoppingBag(cadId);
  }

  removeFromCart(cadId) {
    this.props.actions.removeFromShoppingBag(cadId);
  }

  addAllToShoppingBag() {
    let cadsToFilter = window.cadsToFilter;

    cadsToFilter = cadsToFilter.filter(cad => (cad.available != null && cad.available > 0) || (cad.future_ats != null && cad.future_ats > 0));

    let cadToFilterLength = cadsToFilter.length;
    let selectedLength = Object.keys(this.props.cadReducer.shoppingBag).length;

    let cadIds = cadsToFilter.map(cad => cad.id);

    let allCadsInBag = (cadToFilterLength == selectedLength);

    if (allCadsInBag) {
      this.props.actions.bulkRemoveFromShoppingBag();
    } else {
      this.props.actions.bulkAddToShoppingBag(cadIds);
    }

    $('#add-all-to-cart').addClass('loading');
    clearTimeout(window.addAllToCartTimeout);
    window.addAllToCartTimeout = setTimeout(function() {
      $('#add-all-to-cart').removeClass('loading');
    }.bind(this), 300);
  }

  shareALinkModalClose() {
    this.setState({shareALinkModalOpen: false});
  }

  createHold() {
    var keys = Object.keys(window.cadIds);

    this.props.actions.bulkRemoveFromShoppingBag();
    for (let i=0; i<keys.length; i++) {
      let cadId = keys[i];
      this.props.actions.addToShoppingBag(cadId);
    }
    this.props.history.push('/sales-orders/new?from=cadspage');
  }

  toggleReadyToShip() {
    this.setState({readyToShip: !this.state.readyToShip})

    if (!this.props.cadReducer.showHold) { this.props.actions.toggleHideableCheckbox('showPO') }
    if (!this.props.cadReducer.showHold) { this.props.actions.toggleHideableCheckbox('showHold') }
    if (!this.props.cadReducer.showOnHand) { this.props.actions.toggleHideableCheckbox('showOnHand') }

    this.clearCadCard()
  }

  toggleOversold() {
    this.setState({oversold: !this.state.oversold})
    this.clearCadCard()
  }

  toggleHasVponumber() {
    this.setState({hasVponumber: !this.state.hasVponumber})
    this.clearCadCard()
  }

  toggleHasVpoLoadingDate() {
    this.setState({hasVpoLoadingDate: !this.state.hasVpoLoadingDate})
    this.clearCadCard()
  }

  toggleInProgress() {
    this.setState({inProgress: !this.state.inProgress})
    this.clearCadCard()
  }

  clearCadCard(refresh = false) {
    window.cadDescription = {}

    if (refresh) {
      this.setState({loadingCads: true})
      setTimeout(() => {
        this.setState({loadingCads: false})
      }, 100)
    }
  }

  //sliders
  updateOnHandState(minOnHand, maxOnHand) {
    this.setState({minOnHand, maxOnHand})
  }

  updateHoldQtyState(minHoldQty, maxHoldQty) {
    this.setState({minHoldQty, maxHoldQty})
  }

  updateATSState(minUnits, maxUnits) {
    this.setState({minUnits, maxUnits})
  }

  printOnChange(event, {value}) {
    this.props.actions.updatePrint(value)
    setTimeout(this.search, 100)
  }

  patternOnChange(event, {value}) {
    this.props.actions.updatePattern(value)
    setTimeout(this.search, 100)
  }

  openBestSellersModal = () => {
    this.setState({bestSellersModalOpen: true, bestSellersCad: window.bestSellersCad})
  }

  closeBestSellersModal = () => {
    this.setState({bestSellersModalOpen: false, bestSellersCad: null})
  }

  render() {
    let {correctPin} = this.state

    if (this.state.loadingCads && this.state.loadingSalesHistoryByStyleNumber) {
      return (
        <Dimmer active>
          <Loader size='massive'>Loading</Loader>
        </Dimmer>
      )
    }

    const {user, customer, setMessage} = this.context
    let {last5Searches} = this.props.cadReducer

    let categories = customer ? Utils.objectsToOptionsHash([...customer.categories], 'id', 'name') : []
    let subcategories = customer ? Utils.objectsToOptionsHash([...customer.subcategories], 'id', 'name') : []
    let divisions = customer ? Utils.objectsToOptionsHash([...customer.divisions], 'id', 'name') : []
    let brands = customer ? Utils.objectsToOptionsHash([...customer.brands], 'id', 'name') : []

    let colors = customer ? Utils.objectsToOptionsHash([...customer.colors], 'id', 'name') : []
    let fabrications = customer ? Utils.objectsToOptionsHash([...customer.fabrications], 'id', 'name') : []
    let packs = customer ? Utils.objectsToOptionsHash([...customer.packs], 'id', 'name') : []
    let seasons = customer ? Utils.objectsToOptionsHash([...customer.seasons], 'id', 'name') : []

    let sizes = customer ? Utils.objectsToOptionsHash([...customer.sizes], 'id', 'name') : []

    if (customer != null && this.props.cadReducer.category.length == 1) {
      let category = customer.categories.filter((x) => {return x.name == this.props.cadReducer.category[0]})[0]

      if (category != null && category.subcategories != null && category.subcategories.length > 0) {
        subcategories = category.subcategories
          .map(x => customer.subcategories.find(el => el.id == x))
          .map((x) => {return { text: x.name } })
      }
    }

    let sub2 = []
    if (customer != null && this.props.cadReducer.subCategory.length == 1) {
      let subcategory = customer.subcategories.filter((x) => {return x.name == this.props.cadReducer.subCategory[0]})[0]
      if (subcategory != null && subcategory.grandSubcategories != null && subcategory.grandSubcategories.length > 0) {
        sub2 = subcategory.grandSubcategories
          .map(x => customer.grandSubcategories.find(el => el.id == x))
          .map((x) => {return { text: x.name } })
      }
    }

    categories = categories.optionsHashTextAsKey()
    subcategories = subcategories.filter(x => x.text != 'MISSING SUB-CATEGORY').optionsHashTextAsKey()
    divisions = divisions.optionsHashTextAsKey()
    brands = brands.optionsHashTextAsKey()
    colors = colors.optionsHashTextAsKey()
    fabrications = fabrications.optionsHashTextAsKey()
    packs = packs.filter(x => x.text != 'MISSING PACKS').optionsHashTextAsKey()
    seasons = seasons.optionsHashTextAsKey()
    sizes = sizes.optionsHashTextAsKey()
    sub2 = sub2.optionsHashTextAsKey()

    let role = sessionStorage.getItem('currentUserRole')

    let cadsToSearch = []
    if (window.cads != null) {
      if (this.state.salesHistoryAccount == '')  {
        cadsToSearch = this.sortCads(this.searchFilter(window.cads.slice(0)))
      } else {
        cadsToSearch = this.sortCads(this.searchFilter(this.state.cadsSalesHistory))
      }
    }
    window.cadsToSearch = cadsToSearch

    let cadsToSearchSliced = []
    if (cadsToSearch.length > 0) {
      cadsToSearchSliced = cadsToSearch.slice(0, this.state.sliceNum)
    }

    let isGuest = sessionStorage.getItem('isGuest') == 'TRUE'
    window.isGuest = isGuest

    let isAdmin = ['admin','superadmin','finance'].includes(role)
    let isSales = ['sales','sales_assistant','inventory'].includes(role)
    let isShowroom = role == 'showroom' || isGuest
    let canApproveBrands = !!user && canApproveBrandsList.includes(user.email)

    //set customer options
    let myCustomerOptions = window.currentUserCustomAccess



    if (isCustomer() && myCustomerOptions != null) {
      myCustomerOptions['fields'] = customerFields
      myCustomerOptions['futureAtsMonths'] = next4Months
    }


    hideableInfo = removeFromHideableInfo(hideableInfo, myCustomerOptions, isGuest, isAdmin, isSales, isShowroom, this.props.cadReducer.defaultAccount, this.state.salesHistoryAccount)

    let hideShowPanels = getHideShowPanels(isGuest, hideableInfo, this.toggleHideableCheckbox, this.props.cadReducer, this.selectAllHideableCheckbox, this.onChangeHideableInput)

    let optionsLast5Searches = last5Searches.map((x) => {return {key: x, text: x, value: x}})

    let brandOptions = brands.slice(0)
    let divisionOptions = divisions.slice(0)
    let categoryOptions = categories.slice(0)
    let subCategoryOptions = subcategories.slice(0)
    let colorOptions = colors.slice(0)
    let fabricationOptions = fabrications.slice(0)
    let packsOptions = packs.slice(0)
    let seasonOptions = seasons.slice(0)
    let sizesOptions = sizes.slice(0)
    let sub2Options = sub2.slice(0)
    let futureAtsMonthsLocal = futureAtsMonthsOptions
    if (isAdmin) {
      brandOptions.push({ key: 'Missing', text: 'Missing', value: 'Missing' })
      divisionOptions.push({ key: 'Missing', text: 'Missing', value: 'Missing' })
      categoryOptions.push({ key: 'Missing', text: 'Missing', value: 'Missing' })
      subCategoryOptions.push({ key: 'Missing', text: 'Missing', value: 'Missing' })
      colorOptions.push({ key: 'Missing', text: 'Missing', value: 'Missing' })
      fabricationOptions.push({ key: 'Missing', text: 'Missing', value: 'Missing' })
      packsOptions.push({ key: 'Missing', text: 'Missing', value: 'Missing' })
      seasonOptions.push({ key: 'Missing', text: 'Missing', value: 'Missing' })
      sizesOptions.push({ key: 'Missing', text: 'Missing', value: 'Missing' })
      if (sub2Options.length > 0) {
        sub2Options.push({ key: 'Missing', text: 'Missing', value: 'Missing' })
      }
    }

    if (myCustomerOptions != null) {
      if (myCustomerOptions.brand != null) {brandOptions = sortMapOptions(myCustomerOptions.brand)}
      if (myCustomerOptions.division != null) {divisionOptions = sortMapOptions(myCustomerOptions.division)}
      if (myCustomerOptions.category != null) {categoryOptions = sortMapOptions(myCustomerOptions.category)}
      if (myCustomerOptions.subCategory != null) {subCategoryOptions = sortMapOptions(myCustomerOptions.subCategory)}
      if (myCustomerOptions.futureAtsMonths != null) {futureAtsMonthsLocal = sortMapOptions(myCustomerOptions.futureAtsMonths)}
    }

    let myStatusOptions = isGuest ? guestStatusOptions.slice(0) : isWarehouse() ? warehouseStatusOptions.slice(0) : cadStatusOptions.slice(0)

    if (myCustomerOptions != null && myCustomerOptions.status != null) {
      myStatusOptions = myStatusOptions.filter(x => myCustomerOptions.status.includes(x.key))
    } else {
      myStatusOptions = myStatusOptions.filter(x => !customerOnlyOptions.includes(x.key))
    }

    if (window.cadDescription == null || Object.keys(window.cadDescription).length == 0) {
      window.sales_order_total = {};
      window.sales_order_ponumbers = {};
      window.sales_order_startship = {};
      window.sales_order_account = {};
      window.sales_order_price = {};
      window.sales_order_export_rows = {};
      window.shipped_total = {};
      window.shipped_ponumbers = {};
      window.shipped_startship = {};
      window.shipped_account = {};
      window.shipped_price = {};
      window.shipped_export_rows = {};
      window.hold_total = {};
      window.hold_ponumbers = {};
      window.hold_startship = {};
      window.hold_account = {};
      window.hold_price = {};
      window.hold_export_rows = {};
      window.worksheet_total = {};
      window.worksheet_ponumbers = {};
      window.worksheet_startship = {};
      window.worksheet_account = {};
      window.worksheet_price = {};
      window.worksheet_export_rows = {};
    }

    window.fullscreenIndex = null;

    const exportOptions = [
      { key: 'PDF', text: 'PDF', value: 'PDF' },
      { key: 'Excel', text: 'Excel', value: 'Excel' },
      { key: 'UPC', text: 'UPC', value: 'UPC' }
    ]

    if (!isCustomer()) {
      exportOptions.push({ key: 'Save as line sheet', text: 'Save as line sheet', value: 'Save as line sheet' })
    }

    const searchForOptions = [{ key: 'Cad', text: 'Cad', value: 'Cad' }]
    if (!this.state.showMultiSearch) {searchForOptions.push({ key: 'Sales Order', text: 'Sales Order', value: 'Sales Order' })}
    searchForOptions.push({ key: 'Container #', text: 'Container #', value: 'Container #' })

    let excelExportError = (this.props.cadReducer.showFutureATS || this.props.cadReducer.showFutureStock) && (this.props.cadReducer.showShipped || this.props.cadReducer.showPO || this.props.cadReducer.showHold || this.props.cadReducer.showWorksheet || this.props.cadReducer.showOnHand)
    let excelExportError2 = this.props.cadReducer.showFutureStock && (this.props.cadReducer.showFutureATS || this.props.cadReducer.showATS)

    let loading =  this.state.loadingCads || this.state.loadingSalesHistoryByAccount //this.state.loadingSearch ||

    let showDateInputs = this.state.salesHistoryAccount != '' || ['Shipped', 'PO', 'Hold', 'Worksheet'].indexOf(this.props.cadReducer.cadStatus) > -1

    //get rep names
    let {userEmail, repNames} = this.state
    let repOptions = getRepOptions(repNames, user)

    let addToPlaylistPanes = [];
    if (this.state.playlists.length > 0) {
      addToPlaylistPanes.push(
        { menuItem: 'Add to existing line sheet', render: () => <Tab.Pane>
          <Table basic='very'>
            <Table.Body>
              {this.state.playlists.map(this.addToPlaylistRow)}
            </Table.Body>
          </Table>


        </Tab.Pane> }
      );
    }


    addToPlaylistPanes.push(
      { menuItem: 'Create new line sheet', render: () => <Tab.Pane>
        {this.state.showNewPlaylistSuccess ? (
          <Table basic='very'>
            <Table.Row>
              <Table.Cell>{window.playlist_name}</Table.Cell>
              <Table.Cell style={{width: '30%', textAlign: 'right'}}>
                <Button basic style={{marginLeft: '30px'}} onClick={this.goToNewPlaylist}>View</Button>
              </Table.Cell>
            </Table.Row>
          </Table>
        ) : (
          <Form onSubmit={this.submitNewPlaylist}>
            <label style={{marginBottom: '5px', display: 'block'}}>Line sheet title *</label>
            <Input id="my-new-playlist-modal-input" placeholder='Enter line sheet title' fluid pattern=".{5,}" required title="5 characters minimum"/><br/>
            <center>
              <Button type="submit" primary>Save</Button><br/>
              <small>{window.addToPlaylistCadStyleNumber} <span style={{opacity: '0.5'}}>(cad id: {window.addToPlaylistCadId})</span> will be automatically added to new line sheet</small>
            </center>
          </Form>
        )}
      </Tab.Pane> },
    );



    return (
      <div style={{padding: '50px 60px'}}>
        {this.state.showReportQcIssueSuccess ? (
          <Message
            onDismiss={this.showReportQcIssueSuccessDismiss}
            icon='thumbs up outline'
            header='You have successfully reported QC on the CAD'
          />
        ) : null}
        <BulkEditModal isDesign={isDesign()} open={this.state.bulkModalOpen} onClose={this.bulkModalClose}
          categories={categories} customerCategories={customer ? customer.categories : null} subcategories={subcategories} divisions={divisions} brands={brands}
          colors={colors} fabrications={fabrications} packs={packs} seasons={seasons} sizes={sizes} customerSubCategories={customer ? customer.subcategories : null}
          customerGrandSubcategories={customer ? customer.grandSubcategories : null}  orgId={customer ? customer.id : null}/>

        {cadViewer(this.updateNumSelected, true)}

        <Modal size={'small'} open={this.state.shareALinkModalOpen}>
          <Modal.Header>Share a Link</Modal.Header>
          <Modal.Content>
            <b>Create an email and copy in the subject and body.</b>
            <Input value={window.emailSub} label="Email subject" style={{width: '100%', marginTop: '10px'}}/>
            <TextArea value={window.emailBody} style={{width: '100%', minHeight: '100px', marginTop: '10px', padding: '10px'}} />
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.shareALinkModalClose}>
              Done
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal trigger={<Button id="show-pdf-modal" style={{display: 'none'}}>Show Modal</Button>} closeIcon>
          <Modal.Header>Export CADs to PDF</Modal.Header>
          <Modal.Content>
            <Button.Group>
              <Button active={this.state.cadsPerPage == "One"} onClick={this.setCadsPerPage.bind(null, "One")}>One</Button>
              <Button active={this.state.cadsPerPage == "Two"} onClick={this.setCadsPerPage.bind(null, "Two")}>Two</Button>
              <Button active={this.state.cadsPerPage == "Six"} onClick={this.setCadsPerPage.bind(null, "Six")}>Six</Button>
            </Button.Group>
              &nbsp; per Page
            <br/>
            <br/>
            <Input id="cover-page-title" placeholder='Enter Cover Page Title (optional)' fluid />
            <br/>
            <Input id="cover-page-sub-title" placeholder='Enter Cover Page Sub-Title (optional)' fluid />
            <br/>
            <Accordion panels={hideShowPanels} exclusive={false}/>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.downloadPdf} primary>
              Download PDF
            </Button>
            <Button
              onClick={this.emailPdf}
              positive
              labelPosition='right'
              icon='envelope'
              content='Email PDF'
            />
          </Modal.Actions>
        </Modal>

        <ExportToExcelModal excelExportError={excelExportError}
          excelExportError2={excelExportError2}
          hideShowPanels={hideShowPanels}
          isGuest={isGuest}
          isAdmin={isAdmin}
          cadReducer={this.props.cadReducer}
          downloadExcel={this.downloadExcel}
          salesHistoryAccount={this.state.salesHistoryAccount}
          toggleExportByMonth={this.toggleExportByMonth}
          exportByMonth={this.state.exportByMonth}
          toggleExportByBrand={this.toggleExportByBrand}
          exportByBrand={this.state.exportByBrand}
          toggleExportNoImages={this.toggleExportNoImages}
          exportNoImages={this.state.exportNoImages}
          toggleCombineCads={this.toggleCombineCads}
          combineCads={this.state.combineCads}
          toggleHideUnitsUnder={this.toggleHideUnitsUnder}
          hideUnitsUnder={this.state.hideUnitsUnder}
          hideUnitsUnderOnChange={this.hideUnitsUnderOnChange}
          toggleExportMargins={this.toggleExportMargins}
          exportMargins={this.state.exportMargins}
          multipleBrandsSelected={this.state.multipleBrandsSelected}
        />

        <ShowHideModal open={this.state.viewShowHideModalOpen}
          onClose={this.viewShowHideModalClose}
          isGuest={isGuest}
          hideShowPanels={hideShowPanels}
        />

        {markAsBestSellerModals(this.state, this.bestSellerModalClose, this.poorSellerAccountOnChange, this.averageSellerAccountOnChange, this.bestSellerAccountOnChange, this.saveBestPoorSeller)}
        {bestSellerSuccess(this.state, this.bestSellerSuccessHandleDismiss, this.averageSellerSuccessHandleDismiss, this.poorSellerSuccessHandleDismiss)}

        <Modal size={'small'} open={this.state.addToPlaylistModalOpen} onClose={this.addToPlaylistModalClose} closeIcon>
          <Modal.Header>Add to line sheet: {window.addToPlaylistCadStyleNumber} <small style={{opacity: '0.5'}}>(cad id: {window.addToPlaylistCadId})</small></Modal.Header>
          <Modal.Content>
            <Tab panes={addToPlaylistPanes} />
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.addToPlaylistModalClose} secondary>
              Done
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal size={'small'} open={this.state.viewPlaylistsModalOpen} onClose={this.viewPlaylistsModalClose} closeIcon>
          <Modal.Header>View Line Sheets</Modal.Header>
          <Modal.Content>
            {this.state.playlists.length == 0 ? (
              <div>You have no line sheets yet, click the 3 dots to the bottom right of a cad image, then click "Add to Line Sheet" to create a new one.</div>
            ) : null}
            <Table basic='very'>
              <Table.Body>
                {this.state.playlists.length > 0 ?
                  this.state.playlists.map(this.playlistRow)
                  :
                  null
                }
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.viewPlaylistsModalClose} secondary>
              Done
            </Button>
          </Modal.Actions>
        </Modal>

        {this.state.multiSearch.length > 2 ?
          <Message id="multi-search-message"
            icon='list'
            header={
              <div className="header">
                You are searching multiple {this.state.searchPONumber ? 'PO#' : (this.state.searchContainerNumber ? 'container #' : 'style numbers')}. &nbsp;&nbsp;

                <span id="multiSearchMessage"></span>


                  <div id="multi-search-not-found">
                    The following are NOT found:
                    <ul>

                    </ul>
                  </div>


              </div>
            }
          />
        : null}

        {this.doingPONumberSearch() ?
          <Message style={{marginBottom: '20px'}}
            icon='file'
            header={
              <div className="header">

                <Grid columns={3} stackable>
                  <Grid.Row>
                    <Grid.Column>
                      {
                        window.searchRefNumber == null
                        ?
                        null
                        :
                        <div>REF &#35;: {window.searchRefNumber}</div>
                      }
                      {
                        window.searchPoNumber == null
                        ?
                        <div>PO &#35;: {this.state.searchString.trimUpcase()}</div>
                        :
                        <div>PO &#35;: {window.searchPoNumber}</div>
                      }
                    </Grid.Column>
                    <Grid.Column>
                      {window.searchPONumberAccount == null ?
                        <span>
                          &nbsp;&nbsp; The Sales Order was NOT FOUND!
                        </span>
                        :
                        <span className="white-space-nowrap-desktop">
                          &nbsp;&nbsp; Account Name: &nbsp;

                          {window.searchPONumberAccount}
                        </span>
                      }
                    </Grid.Column>
                    <Grid.Column style={{textAlign: 'right'}}>
                    {window.searchPONumberAccount == null ?
                      null
                      :
                      <span className="white-space-nowrap-desktop">
                        &nbsp;&nbsp; Order Type: &nbsp;

                        {window.searchPONumberOrderType}

                        <br/>&nbsp;&nbsp; Start Ship: &nbsp;

                        {window.searchPONumberStartShip}{window.searchPONumberLatestStartShip}
                      </span>
                    }
                    </Grid.Column>
                  </Grid.Row>
                </Grid>


              </div>
            }
          />
        : null}

        {
          this.state.searchContainerNumber && !this.state.hideContainerNumber && ((this.state.searchString.length > 2 && cadsToSearch.length > 0) || (this.state.showMultiSearch && this.state.multiSearch.length > 2 && this.state.multiSearchStyleNumbers.length > 0))
          ?
          <div style={{paddingTop: '50px', marginBottom: '40px'}}>
            {
              isAdmin || canSeeContainerTotals()
              ?
              <div style={{display: 'inline-block', verticalAlign: 'top', marginRight: '50px'}}>
                <ContainerNumberTotals number={this.state.searchString} numbers={this.state.multiSearchStyleNumbers} />
              </div>
              :
              null
            }
          </div>
          :
          null
        }

        <div className="group">
          {isDesign() ? null :
            <div id='cads-search-type-input'>
              <label>Search for</label>
              <Dropdown placeholder='Search for' fluid selection search value={this.state.searchPONumber ? 'Sales Order' : (this.state.searchContainerNumber ? 'Container #' : 'Cad')} options={searchForOptions} onChange={this.searchForOnChange}/>
            </div>
          }

          {this.state.showMultiSearch ?
            <div id="multi-search-textarea-holder" >
              <small style={{opacity: '0.5'}}>Each {this.state.searchPONumber ? 'PO#' : (this.state.searchContainerNumber ? 'container #' : 'style number')} must be on a separate line!</small>
              <TextArea id="multi-search-textarea" />
            </div>
          : null}

          {this.state.showMultiSearch ? null :
            <div id='cads-search-input'>
              <Input
                size='big'
                icon='search'
                placeholder={this.state.searchPONumber ? 'Search PO #...' : (this.state.searchContainerNumber ? 'Search Container #...' : 'Search Style #...')}
                disabled={this.state.loadingCads || this.state.loadingSalesHistoryByAccount}
                style={this.state.loadingCads || this.state.loadingSalesHistoryByAccount ? {cursor: 'progress'} : null}
                loading={this.state.loadingCads || this.state.loadingSalesHistoryByAccount || this.state.loadingSalesHistoryByStyleNumber}
                onChange={this.handleSearchChange}
              />
            </div>
          }

          <div id="cads-default-contact-view">
            <div style={{marginTop: '6px'}}>
              Default contact view
              {DefaultContactInfoCircle}
            </div>

            {
              isGuest || isCustomer()
              ?
              <Input style={{cursor: 'not-allowed'}} disabled fluid value={this.props.cadReducer.defaultAccount} />
              :
              <Dropdown placeholder='Default contact view' fluid multiple selection search value={this.props.cadReducer.defaultAccount} options={this.state.salesHistoryAccounts} onChange={this.defaultAccountOnChange}/>
            }
          </div>

          <h1 style={{marginTop: '25px', marginBottom: '25px'}}>{cadsToSearch.length} cad{cadsToSearch.length == 1 ? '' : 's'} found</h1>
        </div>

        {isGuest ? null :
          <button disabled={this.state.loadingCads || this.state.loadingSalesHistoryByAccount || this.state.loadingSalesHistoryByStyleNumber}
            style={{marginRight: (isDesign() ? null : '11%')}} data-tooltip={(this.state.showMultiSearch ? 'Hide' : 'Show') + ' multi search'} data-position="bottom right" className="basic ui button multiSearchButton" onClick={this.toggleMultiSearch}>
            {this.state.showMultiSearch ? 'hide' : 'show'} <Icon name='list' style={{marginLeft: '2px'}}/>
          </button>
        }

        {this.state.showMultiSearch || this.props.cadReducer.last5Searches.length == 0 || isGuest ? null :
          <div id="recentSearches-holder">
            <Dropdown simple direction='left' text={<small style={{color: 'rgb(202,202,202)'}}>Recent Searches</small>} options={optionsLast5Searches} item onChange={this.last5Searches} value={null} />
          </div>
        }


        <br/>

        <div style={{marginBottom: '10px'}}>
          <Popup content='Select all cads' position='bottom left' trigger={<Button basic icon='mouse pointer' onClick={this.selectAllCads} style={{padding: '14px', marginRight: '10px'}}/>} />

          {isAdmin || isSales ?
            <Popup content='Share a Link' position='bottom left' trigger={<Button basic icon='share alternate' onClick={this.shareLink} style={{padding: '14px', marginRight: '10px', pointerEvents: 'none', opacity: 0.5, display: 'none'}} />} />
          : null}

          {
            isAdmin || canEditCads() || isDesign()
            ?
            <Popup content='Bulk edit cads' position='bottom left' trigger={
              <Button id="bulk-edit-cads" basic icon='pencil' onClick={this.bulkChangeCads} style={{padding: '14px', marginRight: '10px'}}/>
            }/>
            :
            null
          }

          {
            isCustomer() || isDesign() || isWarehouse()
            ?
            null
            :
            (isGuest && this.props.cadReducer.defaultAccount.length > 0) || !isGuest
            ?
            this.state.expandSalesHistory
            ?
            <Popup content='Hide sales info' position='bottom left' trigger={<Button basic icon='compress' onClick={this.compressSalesHistory} style={{padding: '14px', marginRight: '10px'}}/>} />
            :
            <Popup content='Expand sales info' position='bottom left' trigger={<Button basic icon='arrows alternate vertical' onClick={this.expandSalesHistory} style={{padding: '14px', marginRight: '10px'}}/>} />
            :
            null
          }

          {(isAdmin || isSales) ?
            <Popup content='New sales order' position='bottom left' trigger={<Button basic icon='cart' onClick={this.createHold} style={{padding: '14px', marginRight: '10px'}}/>} />
          : null}

          {
            isAdmin || isSales || isCustomer()
            ?
            bestSellersBulkButtons(this.markAsBestSeller, this.markAsAverageSeller, this.markAsPoorSeller)
            :
            null
          }

          {
            isGuest || isCustomer() || isWarehouse()
            ?
            null
            :
            <Popup content='View saved line sheets' position='bottom left' trigger={<Button basic icon='clipboard list' onClick={this.viewPlaylists} style={{padding: '14px', marginRight: '10px'}}/>} />
          }

          <Menu compact style={{marginTop: '-2px', marginRight: '10px', boxShadow: 'none'}}>
            <Dropdown id="export-dropdown" text={this.state.numCadsSelected == 0 ? 'Export' : ('Export (' + this.state.numCadsSelected + ')') } options={exportOptions} simple item onChange={this.exportPdf} value={null} style={{zIndex: 8}}/>
          </Menu>

          <a onClick={this.clearFilters} className="cads-reset-filters">Reset filters</a>
        </div>

        <Grid columns={5} stackable>
          <Grid.Row>
            <Grid.Column>
              {this.props.cadReducer.brand.length == 0 ? (
                <label>&nbsp;</label>
              ) : (
                <label>Brand</label>
              ) }

              <Dropdown style={isGuest ? {pointerEvents: 'none', opacity: 0.5} : {}} placeholder='Brand' search fluid multiple selection options={brandOptions} onChange={this.brandOnChange} value={this.props.cadReducer.brand}/>
            </Grid.Column>
            <Grid.Column>
              {this.props.cadReducer.division.length == 0 ? (
                <label>&nbsp;</label>
              ) : (
                <label>Division</label>
              ) }

              <Dropdown style={isGuest ? {pointerEvents: 'none', opacity: 0.5} : {}} placeholder='Division' search fluid multiple selection options={divisionOptions} onChange={this.divisionOnChange} value={this.props.cadReducer.division}/>
            </Grid.Column>
            <Grid.Column>
              {this.props.cadReducer.category.length == 0 ? (
                <label>&nbsp;</label>
              ) : (
                <label>Category</label>
              ) }

              <Dropdown style={isGuest ? {pointerEvents: 'none', opacity: 0.5} : {}} placeholder='Category' search fluid multiple selection options={categoryOptions} onChange={this.categoryOnChange} value={this.props.cadReducer.category}/>
            </Grid.Column>
            <Grid.Column>
              {this.props.cadReducer.subCategory.length == 0 ? (
                <label>&nbsp;</label>
              ) : (
                <label>Sub-category</label>
              ) }

              <div style={isGuest && window.initialSubCategory != null && window.initialSubCategory.length > 0 ? {cursor: 'not-allowed'} : {}}>
                <Dropdown style={isGuest && window.initialSubCategory != null && window.initialSubCategory.length > 0 ? {pointerEvents: 'none', opacity: 0.5} : {}} placeholder='Sub-category' search fluid multiple selection options={subCategoryOptions} onChange={this.subCategoryOnChange} value={this.props.cadReducer.subCategory} />
              </div>
            </Grid.Column>
            <Grid.Column>
              <label>
                Status
                <StatusInfoCircle cadStatus={this.props.cadReducer.cadStatus}/>
              </label>
              <div style={isGuest ? {cursor: 'not-allowed'} : {}}>
                <Dropdown style={isGuest && this.props.cadReducer.defaultAccount.length == 0 ? {pointerEvents: 'none', opacity: 0.5} : {}} placeholder='Status' search fluid selection options={myStatusOptions} onChange={this.cadStatusOnChange} value={this.props.cadReducer.cadStatus} />
              </div>

              {
                ['PO', 'Hold', 'Worksheet'].indexOf(this.props.cadReducer.cadStatus) != -1 && this.state.salesHistoryAccount == ''
                ?
                <div style={{paddingTop: '5px', display: 'inline-block', paddingRight: '15px'}}>
                  <Checkbox label='Ready To Ship' onChange={this.toggleReadyToShip} checked={this.state.readyToShip} /><br/>
                  <Checkbox label='Has VPO Loading Date' onChange={this.toggleHasVpoLoadingDate} checked={this.state.hasVpoLoadingDate} style={{marginTop: '5px'}}/><br/>
                  {
                    isCustomer()
                    ?
                    null
                    :
                    <React.Fragment>
                      <Checkbox label='Has VPO #' onChange={this.toggleHasVponumber} checked={this.state.hasVponumber} style={{marginTop: '5px'}}/><br/>
                      <Checkbox label='Oversold' onChange={this.toggleOversold} checked={this.state.oversold} style={{marginTop: '5px'}}/>
                    </React.Fragment>
                  }

                </div>
                :
                null
              }

              {this.props.cadReducer.cadStatus == 'Shipped' ?
                <div style={{paddingTop: '5px', display: 'inline-block', paddingRight: '15px'}}>
                  <Checkbox label='In Progress' onChange={this.toggleInProgress} checked={this.state.inProgress} /> <span className="spinning-cog"><Icon name="cog" /></span>
                </div>
              : null}

            </Grid.Column>
          </Grid.Row>
          <Grid.Row id="cads-page-grid-second-row">
            <Grid.Column>
              {this.props.cadReducer.color.length == 0 ? (
                <label>&nbsp;</label>
              ) : (
                <label>Color</label>
              ) }

              <Dropdown style={isGuest ? {pointerEvents: 'none', opacity: 0.5} : {}} placeholder='Color' search fluid multiple selection options={colorOptions} onChange={this.colorOnChange} value={this.props.cadReducer.color} />
            </Grid.Column>
            <Grid.Column>
              {
                this.props.cadReducer.fabrication.length == 0 ?
                <label>&nbsp;</label>
                :
                <label>Fabrication</label>
              }

              <Dropdown style={isGuest ? {pointerEvents: 'none', opacity: 0.5} : {}} placeholder='Fabrication' search fluid multiple selection options={fabricationOptions} onChange={this.fabricationOnChange} value={this.props.cadReducer.fabrication} />
            </Grid.Column>
            <Grid.Column>
              {this.props.cadReducer.packs.length == 0 ? (
                <label>&nbsp;</label>
              ) : (
                <label>Packs</label>
              ) }

              <Dropdown style={isGuest ? {pointerEvents: 'none', opacity: 0.5} : {}} placeholder='Packs' search fluid multiple selection options={packsOptions} onChange={this.packsOnChange} value={this.props.cadReducer.packs} />
            </Grid.Column>
            <Grid.Column>
              {isGuest && this.props.cadReducer.season.length == 0 ? null :
                <div>
                  {this.props.cadReducer.season.length == 0 ? (
                    <label>&nbsp;</label>
                  ) : (
                    <label>Season</label>
                  ) }

                  <Dropdown style={isGuest ? {pointerEvents: 'none', opacity: 0.5} : {}} placeholder='Season' search fluid multiple selection options={seasonOptions} onChange={this.seasonOnChange} value={this.props.cadReducer.season} />
                </div>
              }
            </Grid.Column>
            <Grid.Column>
              {
                isDesign()
                ?
                printsFilter(this.printOnChange, this.props.cadReducer.print)
                :
                null
              }
            </Grid.Column>
          </Grid.Row>
          <CadFilterSection
            isGuest={isGuest}
            isSales={isSales}
            isAdmin={isAdmin}
            isDesign={isDesign()}
            isProduction={isProduction()}
            cadReducer={this.props.cadReducer}

            futureAtsMonths={futureAtsMonthsLocal}
            futureAtsMonthsOnChange={this.futureAtsMonthsOnChange}
            factoryOnChange={this.factoryOnChange}
            marginsOptionsAdmin={marginsOptionsAdmin}
            marginsOnChange={this.marginsOnChange}
            repOptions={repOptions}
            repOnChange={this.repOnChange}
            reps={this.state.reps}
            warehouseOnChange={this.warehouseOnChange}

            sub2Options={sub2Options}
            showDateInputs={showDateInputs}

            reps={this.state.reps}

            dateToOnChange={this.dateToOnChange}
            dateFromOnChange={this.dateFromOnChange}
            sub2OnChange={this.sub2OnChange}
            hideableInfo={hideableInfo}

            printOnChange={this.printOnChange}
            patternOnChange={this.patternOnChange}

          />
        </Grid>

        <Accordion style={{marginBottom: '14px', marginTop: '14px', cursor: (this.props.cadReducer.cadStatus == 'ATS or Future ATS' ? 'not-allowed' : ''), opacity: (this.props.cadReducer.cadStatus == 'ATS or Future ATS' ? '0.1' : '')}}>
          <Accordion.Title
            active={this.state.showATSSlider}
            onClick={this.showATSSliderHandleClick}
            style={{display: 'inline-block', cursor: (this.props.cadReducer.cadStatus == 'ATS or Future ATS' ? 'not-allowed' : '')}}
          >
            <Icon name='dropdown' />
            Sliders &nbsp; <small style={{opacity: 0.5, position: 'relative', bottom: '1px'}}>(Units
            {
              isCustomer() || isWarehouse() ? '' : ' / Page'
            }
            {
              this.props.cadReducer.cadStatus == 'Hold' ? ' / Hold Age' : null
            }
          )</small>
          </Accordion.Title>
          <Accordion.Content active={this.state.showATSSlider} style={{pointerEvents: (this.props.cadReducer.cadStatus == 'ATS or Future ATS' ? 'none' : '')}}>
            {
              customer
              ?
              <AtsFilter isAdmin={isAdmin} isSales={isSales} isWarehouse={isWarehouse()} isShowroom={isShowroom} isGuest={isGuest}
                cadsToSearch={cadsToSearch}
                updateOnHandState={this.updateOnHandState}
                updateHoldQtyState={this.updateHoldQtyState}
                updateATSState={this.updateATSState}
                updateFutureATSState={this.updateFutureATSState}
                updatePageState={this.updatePageState}
                updateHoldAgeState={this.updateHoldAgeState}

                salesHistoryAccount={this.state.salesHistoryAccount}
                searchString={this.state.searchString}
                missingStyles={this.state.missingStyles}
                orgId={customer ? customer.id : null}
              />
              :
              null
            }
          </Accordion.Content>
        </Accordion>

        {
          isAdmin || isSales || isProduction() || isCustomer()
          ?
          <CadTotalsAccordion cadsToSearch={cadsToSearch}
            cadReducer={this.props.cadReducer}
            salesHistoryAccount={this.state.salesHistoryAccount}
            isCustomer={isCustomer()}
            user={user}
            isAdmin={isAdmin}
          />
          :
          null
        }

        {isAdmin ? <CadIdsAccordion cads={cadsToSearch}/> : null}

        <div style={{display: 'none'}}>Status: {this.props.cadReducer.cadStatus == 'All' ? '—' : this.props.cadReducer.cadStatus}</div>
        <div style={{display: 'none'}}>Sales history: {this.state.salesHistoryAccount == '' ? '—' : this.state.salesHistoryAccount}</div>
        <div style={{display: 'none'}}># Cads: {window.cads == null ? null : window.cads.length}</div>
        <div style={{display: 'none'}}>Default account: {this.props.cadReducer.defaultAccount.length == 0 ? '—' : this.props.cadReducer.defaultAccount}</div>

        <div className="cads-visible-fields-button">
          <Button onClick={this.viewShowHide} size='huge' data-tooltip="Select visible fields" data-position="bottom right" basic icon='eye'/>
        </div>

        <CadsLoadingBox
          loadingCads={this.state.loadingCads}
          loadingSalesHistoryByStyleNumber={this.state.loadingSalesHistoryByStyleNumber}
          loadingSalesHistoryByAccount={this.state.loadingSalesHistoryByAccount}
          loadingVpos={this.state.loadingVpos}
          loadingContainerNumbers={this.state.loadingContainerNumbers}
        />

        {
          loading ? null :
          cadsToSearchSliced.length == 0 ?
          <center style={{paddingTop: '50px', fontSize: '30px'}}>No results found<br/><br/><small style={{opacity: 0.5, fontSize: '20px'}}>Try a different combination of filters</small></center>
          :
          cadsToSearchSliced.length > 0
          ?
          <div className={"ui stackable cards " + (this.state.layout == 'desktop' ? 'four' : 'three')}>
            {cadsToSearchSliced.map(this.cadCard)}
          </div>
          :
          null
        }


        {this.state.searchPONumber && window.missingStyleNumbersSearchPONumber != null && window.missingStyleNumbersSearchPONumber.length > 0 ?
          <div style={{marginTop: '50px'}}>
            <h2>{window.missingStyleNumbersSearchPONumber.length} style{window.missingStyleNumbersSearchPONumber.length == 1 ? '' : 's'} found that are missing a cad image</h2>

            {
              window.missingStyleNumbersSearchPONumber.map(function(missingStyleNumber) {

                const panels = getOrderPanels(this.state, this.props, missingStyleNumber, missingStyleNumber, checkCanSeePrice(user, this.props.cadReducer.defaultAccount))

                return (
                  <div style={{display: 'inline-block', padding: '20px', margin: '10px', border: '1px solid rgb(242,242,242)'}}>
                    <div style={{fontSize: '18px', fontWeight: 'bold'}}>{missingStyleNumber}</div>
                      {panels.length == 0 ? null : (
                        <Accordion  panels={panels} exclusive={false}/>
                      )}
                  </div>
                );
              }.bind(this))
            }
          </div>
        : null}


        {
          this.props.cadReducer.currentPlaylistId == null && this.state.sliceNum >= this.state.cadsSalesHistory.length && !loading && this.state.salesHistoryAccount != '' && this.state.searchString.length < 3 && this.props.cadReducer.division.length == 0 && this.props.cadReducer.color.length == 0 && this.props.cadReducer.season.length == 0 && [null, 'Shipped', 'PO', 'Hold'].indexOf(this.props.cadReducer.cadStatus) > -1 && this.props.cadReducer.subCategory.length == 0 && this.props.cadReducer.sub2.length == 0 && this.props.cadReducer.fabrication.length == 0 && this.props.cadReducer.brand.length == 0 && this.props.cadReducer.category.length == 0 && this.props.cadReducer.packs.length == 0 && this.state.missingStyles > 0
          ?
          <div style={{marginTop: '50px'}}>
            <h2>{this.state.missingStyles} style{this.state.missingStyles == 1 ? '' : 's'} found that are missing a cad image</h2>

            {window.missingAccountStyleNumbers == null ? null : window.missingAccountStyleNumbers.map(function(missingStyleNumber) {

              const panels = getOrderPanels(this.state, this.props, missingStyleNumber, missingStyleNumber, checkCanSeePrice(user, this.props.cadReducer.defaultAccount))

              return (
                <div style={{display: 'inline-block', padding: '20px', margin: '10px', border: '1px solid rgb(242,242,242)'}}>
                  <div style={{fontSize: '18px', fontWeight: 'bold'}}>{missingStyleNumber}</div>
                    {panels.length == 0 ? null : (
                      <Accordion  panels={panels} exclusive={false}/>
                    )}
                </div>
              );
            }.bind(this))}
          </div>
          :
          null
        }
        <br/><br/>
        {this.props.cadReducer.currentPlaylistId != null ? (
          <div style={{borderTop: '1px solid rgb(206,206,206)', lineHeight: '50px', height: '55px', position: 'fixed', bottom: 0, left: 0, textAlign: 'center', width: '100%', background: 'rgb(242,242,242)'}}>
            <Icon name='clipboard list' /> Line sheet: &nbsp;&nbsp;&nbsp; {this.props.cadReducer.currentPlaylistName}</div>
        ) : null}
        {this.props.cadReducer.currentPlaylistId != null ? (
          <button style={{ opacity: '0.7', position: 'fixed', left: '10px', bottom: '10px', borderRadius: '17px'}} className="ui basic button" onClick={this.closePlaylistGreyBar}><Icon name='close' /> Close line sheet</button>
        ) : null}
        {
          isAdmin || canEditCads() || isDesign()
          ?
          bulkEditCornerButton
          :
          null
        }
        {bestSellerExportCornerButtons(isAdmin || isSales || isCustomer(), this.exportPdf)}
        {scrollToTopButton(this.state.numCadsSelected)}

        {bestSellersModal(this.state.bestSellersModalOpen, this.closeBestSellersModal, this.state.bestSellersCad, this.state.salesHistoryAccounts)}
      </div>
    )
  }
}

const mapStateToProps = state => ({
 ...state
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(cadActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CadsPage)

const getAccountOptions = (shippedData, salesOrderData) => {
  let accountNames = getAccountNames(shippedData).concat(getAccountNames(salesOrderData))
  let distinctAccounts = [...new Set(accountNames)]
  return distinctAccounts.sort().map(x => ({key: x, text: x, value: x}))
}

const getAccountNames = (salesData) => {
  return Object.values(salesData).map(orders => orders.map(order => order.account_name)).flat()
}

export {getAccountOptions}

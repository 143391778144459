const allRoss = [
  'ROSS',
  'ROSS KIDS',
  'ROSS:ROSS - LINDA (SHAPE)',
  'ROSS:ROSS ACTIVEWEAR',
  'ROSS:ROSS BABYDOLLS',
  'ROSS:ROSS CARLY COLUCCI',
  'ROSS:ROSS GRAPHICS/FLEECE',
  'ROSS:ROSS JAIMIE',
  'ROSS:ROSS SHAPEWEAR-LINDA',
  'ROSS:ROSS SLEEP',
  'ROSS:ROSS TAHARI',
  'ROSS:ROSS-JR INTIMATES -JULLIAN',
  'ROSS:ROSS-JR TOPS -JILLAIN'
]

const allTj = [
  'TJX AUSTRALIA',
  'TJX-EU',
  'TJX/MARSHALLS',
  'TJX/MARSHALLS ECOMM'
]

const allDds = [
  "DD'S",
  "DD'S - ALLIE",
  "DD'S - NAOMI",
  "DD'S - PANTIES",
  "DD'S - SAMANTHA",
  "DD'S:DD JUNIOR APPAREL",
  "DD'S:DD JUNIOR APPAREL 2",
  "DD'S:DD SLIPPERS",
  "DD'S:DDS - AMY",
  "DD'S:DDS - MARILYN",
  "DD'S:DDS -ACTIVE - M GRANIERI",
  "DD'S:DDS -JUNIOR - G DEORDIO",
  "DD'S:DDS -MENS",
  "DD'S:DDS COSMETIC BAGS",
  "DD'S:DDS DISCOUNTS - JUNIOR PLUS",
  "DDS"
]

const quickFixAllRoss = (defaultAccount) => {
   if (defaultAccount.includes('ALL ROSS')) {
     defaultAccount = allRoss
   }

   if (defaultAccount.includes('ALL TJ')) {
     defaultAccount = allTj
   }

   if (defaultAccount.includes('ALL DDS')) {
     defaultAccount = allDds
   }

   return defaultAccount
}

export {quickFixAllRoss}

import React from 'react'
import $ from 'jquery'
import {findPrint} from '../cads/prints/find'
import {showPrintButton} from '../cads/prints/show-button'
import {ADD_DAYS_TO_LOADING_DATE, ADD_DAYS_TO_EX_FACTORY_DATE} from '../../modules/constants'
import {isAdmin, isSales, isSalesAssistant, isProduction, isDesign, isCustomer, isWarehouse, isInventory} from '../cads/is-role'
import {Icon, Button, Popup, Label} from 'semantic-ui-react'
import {displayDateText} from '../cads/container-number/display-date-text'
import FutureAts from '../cad-card/future-ats'
import {isLetter} from '../prints/check-valid-style-code'
import ProductionDataPopup from '../cad-card/production-data-popup'
import ContainerNumberLabel from '../cad-card/container-number-label'
import FactoryLabel from '../cad-card/factory-label'
import {pendingPiStyle} from '../cads/pending-pi-style.js'
import DoNotSellMessage from '../cad-card/do-not-sell-message'
import DisplaySalesPrice from '../cad-card/display-sales-price'
import {adminCost} from '../cad-card/admin-cost'
import {formatDate} from '../cads/format-date'
import UpcPopup from '../upc/popup'
import {cadCardRowstyle} from './row-style'
import {canSeeCost} from '../custom-access/can'
import {editBestSellersButton} from '../best-sellers/button'

const getExtra = (cadReducer, cad, imageUrl, imageUrl2, togglePrintImage, showPrintImage, toggleSecondImage, showSecondImage, correctPin, salesHistoryAccount, removeFromCart, addToCart, orgId) => {

  let {isGuest} = window


  let cad_onhand = cad.on_hand
  if (cad_onhand === null || cad_onhand === '') {
    cad_onhand = 'n/a'
  }

  let cad_available = cad.available
  if (cad_available != null && cad_available != '' && cad_available < 0) {
    cad_available = '0'
  }

  if (cad_available === null || cad_available === '') {
    cad_available = 'n/a'
  }

  let on_hand = Number(cad.on_hand)
  if (typeof cad_available === "number" && cad_available > on_hand) {
    cad_available = on_hand
  }

  let cad_future_ats = cad.future_ats

  if (cad_future_ats === null || cad_future_ats === '') {
    cad_future_ats = 'n/a'
  }

  let cad_future_stock = cad.future_stock

  if (cad_future_stock === null || cad_future_stock === '') {
    cad_future_stock = 'n/a'
  }

  let atsDetail = cad.ats_detail

  var poDetail = cad.po_detail
  let poDetailTaken = 0
  if (cad.available < 0) {
    poDetailTaken = cad.available * -1
  }

  let newPoDetail = []
  let newPoDetail_futureStock = []

  if (poDetail != null && poDetail != '') {
    let poDetailRows = poDetail.split(',')

    for(var k=0;k<poDetailRows.length;k++) {
      let parts = poDetailRows[k].split('—')
      let part1 = Number(parts[0])
      let part5 = parts[4]
      let containerNumber = parts[3].trim()
      let ponumber = parts[5].trim()

      let part2 = parts[1]
      let date = null

      if (part2.trim() != '') {
        let etaParts = part2.trim().split('/')

        date = new Date(etaParts[2], etaParts[0]-1, etaParts[1]) // Now // why did i name say "Now"??

        date.setDate(date.getDate() + ADD_DAYS_TO_LOADING_DATE) // Set now + 60 days as the new date

        part2 = "ETA: " + (date.getMonth()+1) + '/' + date.getDate() + '/' + date.getFullYear()
      }

      if (date == null && part5.trim() != '') {
        let dateParts = part5.trim().split('/')

        date = new Date(dateParts[2], dateParts[0]-1, dateParts[1])

        date.setDate(date.getDate() + ADD_DAYS_TO_EX_FACTORY_DATE) // Set now + 30 days as the new date

        part5 = "" + (date.getMonth()+1) + '/' + date.getDate() + '/' + date.getFullYear()
      }

      newPoDetail.push([part1, part2, part5, date, containerNumber, ponumber])
    }

    //sort po details by date!!!!
    newPoDetail.sort(function (a,b) {
      let date_a = a[3]
      let date_b = b[3]

      var d = new Date(2022,0,1)

      if (date_a == null) {date_a = d}
      if (date_b == null) {date_b = d}

      if (date_a > date_b) return 1
      if (date_b > date_a) return -1
    })

    newPoDetail_futureStock = JSON.parse(JSON.stringify(newPoDetail))

    //remove ATS taken after sorting!!!!
    //allow last order to go negative
    let numPo = newPoDetail.length
    for(var k=0;k<numPo;k++) {
      let isLastPo = (k + 1 == numPo)

      let part1 = newPoDetail[k][0]

      if (poDetailTaken != 0) {
        if (part1 > poDetailTaken || isLastPo) {
          part1 -= poDetailTaken
          poDetailTaken = 0
        } else {
          poDetailTaken -= part1
          part1 = 0
        }
      }

      newPoDetail[k][0] = part1
    }

    //Want at least one 0 Future ATS if have po_detail
    //Don't want any 0 Future ATS rows if have a NONZero future ats row
    //Can just delete every row up until the last if qty 0
    newPoDetail = newPoDetail.filter(function(value, index){
        let isZero = value[0] == 0
        let isLastElement = index == newPoDetail.length - 1
        return isLastElement || !isZero
    })
  }

  // Wed Mar 22, 2023
  // default to deliv date for now if can't find po_detail, only will be david org
  var dateText = ''
  if (cad.earliest_delivery_date != null) {
    var dateParts = cad.earliest_delivery_date.split('T')[0].split('-')
    dateText = dateParts[1] + '/' + dateParts[2] + '/' + dateParts[0]
  }

  // if (cad.loading_date != null) {
  //   dateText = 'ETA: ' + cad.loading_date
  // }

  var bestSellerDates
  if (salesHistoryAccount != '' || cadReducer.defaultAccount.length > 0) {
    if (window.salesAccountBestSellersDates != null) {
      bestSellerDates = window.salesAccountBestSellersDates[cad.id]
    }
  }

  var averageSellerDates
  if (salesHistoryAccount != '' || cadReducer.defaultAccount.length > 0) {
    if (window.salesAccountAverageSellersDates != null) {
      averageSellerDates = window.salesAccountAverageSellersDates[cad.id]
    }
  }

  var poorSellerDates
  if (salesHistoryAccount != '' || cadReducer.defaultAccount.length > 0) {
    if (window.salesAccountPoorSellersDates != null) {
      poorSellerDates = window.salesAccountPoorSellersDates[cad.id]
    }
  }

  let created_date = ''
  if (cad.date_created != null && cad.date_created != '') {
    created_date = cad.date_created.split(' ')[0]
  }


  let extra = (
    <div>
      {
        (isAdmin() || isSales() || isSalesAssistant() || isInventory() || isProduction() || isWarehouse()) && cadReducer.showOnHand
        ?
        <div className="ui grid" style={{fontSize: '15px', color: 'black', fontWeight: 'bold', marginBottom: '-10px'}}>
          <div className="eight wide column desktopNoWrapWhiteSpace">
            <span><Icon name='hand paper outline' /> {cad_onhand} On Hand</span>
          </div>
          <div className="eight wide column desktopNoWrapWhiteSpace" style={{textAlign: 'right'}}>
            {
              cadReducer.showWarehouse && ![0, '0', 'n/a'].includes(cad_onhand)
              ?
              <React.Fragment>
                <Icon name='warehouse' /> {cad.sites[0] != null ? cad.sites.join(', ') : <Icon name='question' />}
              </React.Fragment>
              :
              null
            }
          </div>
        </div>
        :
        null
      }

      {
        cadReducer.showATS && !isWarehouse()
        ?
        <div className="ui grid" style={{fontSize: '15px', color: 'black', fontWeight: 'bold', marginBottom: '-10px', marginTop: '-20px'}}>
          <div className="eight wide column desktopNoWrapWhiteSpace">
            {
              cad.age != null && (isAdmin() || isSales() || isSalesAssistant() || isInventory())
              ?
              <Popup content={cad.latest_delivery_date_string} trigger={<span style={{cursor: 'pointer'}}><Icon name='cart plus' /> {cad_available} ATS</span>} />
              :
              <span><Icon name='cart plus' /> {cad_available} ATS</span>
            }
          </div>
          <div className="eight wide column desktopNoWrapWhiteSpace" style={{textAlign: 'right'}}>
            {
              cadReducer.showWarehouse && ![0, '0', 'n/a'].includes(cad_available)
              ?
              <React.Fragment>
                <Icon name='warehouse' /> {cad.sites[0] != null ? cad.sites.join(', ') : <Icon name='question' />}
              </React.Fragment>
              :
              null
            }
          </div>
        </div>
        :
        null
      }

      {
        cadReducer.showFutureStock
        ?
        (
          poDetail == null
          ?
          <div className="ui grid" style={{marginTop: '-20px', marginBottom: '-10px', fontSize: '15px', color: 'black', fontWeight: 'bold'}}>
            <div className="eight wide column desktopNoWrapWhiteSpace">
              <span>
                <Icon name='factory' />
                {cad_future_stock} Future Stock
              </span>
            </div>
            <div className="eight wide column desktopNoWrapWhiteSpace" style={{textAlign: 'right'}}>
              {dateText}
            </div>
          </div>
          :
          <div>
            {newPoDetail_futureStock.map(function(poDetailLine, k) {

              let part1 = poDetailLine[0]
              let part2 = poDetailLine[1]
              if (part2.trim() == '') {
                part2 = poDetailLine[2]
              }


              return (
                <div key={k} className="ui grid" style={{marginTop: '-20px', marginBottom: '-10px', fontSize: '15px', color: 'black', fontWeight: 'bold'}}>
                  <div className="eight wide column desktopNoWrapWhiteSpace">
                    <span>
                      <Icon name='factory' />
                      {part1} Future Stock
                    </span>
                  </div>
                  <div className="eight wide column desktopNoWrapWhiteSpace" style={{textAlign: 'right'}}>
                    {part2}
                  </div>
                </div>
              )
            })}

          </div>
        )
        :
        null
      }

      {
        cadReducer.showFutureATS && !isWarehouse()
        ?
        <FutureAts
          poDetail={poDetail}
          newPoDetail={newPoDetail}
          cadFutureAts={cad_future_ats}
          dateText={dateText}
          cad={cad}
        />
        :
        null
      }

      {
        cadReducer.showPage && (isAdmin() || isSales() || isSalesAssistant() || isInventory() || isGuest) && cad.age != null && cad.age != '' && cad.age != 0
        ?
        <div style={{marginTop: '5px'}}>
          <i className="file outline icon"></i><b>{cad.age}</b> Page
        </div>
        :
        null
      }

      {
        isWarehouse()
        ?
        null
        :
        <React.Fragment>
          {
            salesHistoryAccount != '' || cadReducer.defaultAccount.length > 0 ?
              window.salesAccountBestSellers != undefined && window.salesAccountBestSellers.indexOf(cad.id) > -1 ? (
                <Popup content={'This is a best seller for dates: ' + bestSellerDates.join(', ') } trigger={<Label style={{marginTop: '5px', paddingRight: 0}}>
                  <Icon name='thumbs up outline' /></Label>} />
              ) : null
              :
              isGuest ?
              null
              :
              <Popup style={{ minWidth: '300px', height: (( 100 + cad.best_seller_total * 40 )+'px')}}
                trigger={
                  <Label style={{marginTop: '5px'}} onMouseEnter={updateBestOrPoorSellerData.bind(null, 'best', cad.id, cadReducer.defaultAccount)}>
                    <Icon name='thumbs up outline' /> {cad.best_seller_total == null ? 0 : cad.best_seller_total}
                  </Label>
                }
              >
                <Popup.Header>Best Sellers</Popup.Header>
                <Popup.Content>
                  <div id={"best-seller-info-" + cad.id}></div>
                </Popup.Content>
              </Popup>
          }


          {
            salesHistoryAccount != '' || cadReducer.defaultAccount.length > 0 ?
              window.salesAccountAverageSellers != undefined && window.salesAccountAverageSellers.indexOf(cad.id) > -1 ? (
                <Popup content={'This is a average seller for dates: ' + averageSellerDates.join(', ') } trigger={<Label style={{marginTop: '5px', paddingRight: 0}}>
                  <Icon name='smile outline' /></Label>} />
              ) : null
              :
              isGuest ?
              null
              :
              <Popup style={{ minWidth: '300px', height: (( 100 + cad.best_seller_total * 40 )+'px')}}
                trigger={
                  <Label style={{marginTop: '5px'}} onMouseEnter={updateBestOrPoorSellerData.bind(null, 'average', cad.id, cadReducer.defaultAccount)}>
                    <Icon name='smile outline' /> {cad.average_seller_total == null ? 0 : cad.average_seller_total}
                  </Label>
                }
              >
                <Popup.Header>Average Sellers</Popup.Header>
                <Popup.Content>
                  <div id={"average-seller-info-" + cad.id}></div>
                </Popup.Content>
              </Popup>
          }



          {
            salesHistoryAccount != '' || cadReducer.defaultAccount.length > 0 ?
              window.salesAccountPoorSellers != undefined && window.salesAccountPoorSellers.indexOf(cad.id) > -1 ? (
                <Popup content={'This is a poor seller for dates: ' + poorSellerDates.join(', ') } trigger={<Label style={{marginTop: '5px', paddingRight: 0}}>
                  <Icon name='thumbs down outline' /></Label>} />
              ) : null
              :
              isGuest ?
              null
              :
              <Popup style={{ minWidth: '300px', height: (( 100 + cad.poor_seller_total * 40 )+'px')}}
                trigger={
                  <Label onMouseEnter={updateBestOrPoorSellerData.bind(null, 'poor', cad.id, cadReducer.defaultAccount)}>
                    <Icon name='thumbs down outline' /> {cad.poor_seller_total == null ? 0 : cad.poor_seller_total}
                  </Label>
                }
              >
                <Popup.Header>Poor Sellers</Popup.Header>
                <Popup.Content>
                  <div id={"poor-seller-info-" + cad.id}></div>
                </Popup.Content>
              </Popup>
          }
        </React.Fragment>
      }



      {
        !isCustomer() && !isWarehouse() && ((cad.age != null && cad.age > 300 && cad.available != null && cad.available > 0) || cad.is_deal)
        ?
        <Popup
          trigger={
            <Label style={{paddingRight: 0, marginTop: '5px'}}>
              <Icon name='tag' />
            </Label>
          }
        >
          <Popup.Header>This is a Deal</Popup.Header>
        </Popup>
        :
        null
      }

      {cad.style_number[0] == 'Q' || cad.style_number[1] == 'Q'?
        <Popup
          trigger={
            <Label style={{paddingRight: 0, marginTop: '5px'}}>
              <Icon name='gift' />
            </Label>
          }
        >
          <Popup.Header>This is a Gift with Purchase</Popup.Header>
        </Popup>
      : null}

      {
        (cad.style_number[0] == 'M' && isLetter(cad.style_number[1]) && isLetter(cad.style_number[2]))
        ||
        (cad.style_number[0] == 'M' && cad.style_number[1] == 'Q' && isLetter(cad.style_number[2]) && isLetter(cad.style_number[3]))
        ?
        <Popup
          trigger={
            <span style={{fontSize: '30px', position: 'relative', top: '6px', cursor: 'pointer'}}>🇲🇽</span>
          }
        >
          <Popup.Header>This is a Mexico Customer</Popup.Header>
        </Popup>
      : null}

      {
        (isAdmin() || isSales() || isSalesAssistant() || isInventory() || isDesign() || isProduction())
        ?
        <React.Fragment>
          {
            cad.vpo_total != null && cad.vpo_total > 0
            ?
            <ProductionDataPopup cad={cad}/>
            :
            null
          }
          <ContainerNumberLabel cad={cad} cadReducer={cadReducer}/>
          <FactoryLabel cad={cad} cadReducer={cadReducer}/>
        </React.Fragment>
        :
        null
      }

      <UpcPopup cad={cad} showUPC={cadReducer.showUPC}/>

      {
        (isAdmin() || isSales() || isSalesAssistant() || isInventory() || isProduction()) && (cad.fob_rts || (window.fob_rts_by_style != null && window.fob_rts_by_style[cad.style_number]))
        ?
        <span style={pendingPiStyle}><Icon name="ship"/> FACTORY-RTS</span>
        :
        null
      }

      {cad.pending_pi ? <span style={pendingPiStyle}><Icon name="file text outline" style={{fontSize: '136%'}}/><Icon name="dollar sign" style={{position: 'relative', right: '20px', fontSize: '82%', bottom: '2px', marginRight: '-15px'}}/> PENDING PI</span> : null}

      <DoNotSellMessage cad={cad}/>

      {!isGuest ?
        null
        :
        !((cad.available != null && cad.available > 0 && cadReducer.showATS) || (cad.future_ats != null && cad.future_ats > 0 && cadReducer.showFutureATS))     ?
        <Button color='black' content='Black' basic style={{opacity: 0.3, pointerEvents: 'none', fontSize: '20px', fontWeight: 'bold', marginTop: '5px', padding: '28px 20px', width: '100%'}}>Add to Cart</Button>
        :
        cadReducer.shoppingBag[cad.id] ?
        <Button onClick={removeFromCart.bind(null, cad.id)} positive style={{fontSize: '20px', fontWeight: 'bold', marginTop: '5px', padding: '28px 20px', width: '100%'}}>Added to Cart</Button>
        :
        <Button color='black' content='Black' onClick={addToCart.bind(null, cad.id)} basic style={{fontSize: '20px', fontWeight: 'bold', marginTop: '5px', padding: '28px 20px', width: '100%'}}>Add to Cart</Button>
      }

      {
        (canSeeCost() || isAdmin()) && correctPin
        ?
        adminCost(cad)
        :
        null
      }

      {
        (isSales() || isSalesAssistant() || isInventory() || isProduction() || isAdmin()) && cadReducer.showWholesale
        ?
        <DisplaySalesPrice cad={cad} orgId={orgId}/>
        :
        null
      }

      <div style={{position: 'absolute', right: '3px', top: '-3px', fontSize: '11px', opacity: 0.5}}>Created {created_date}</div>

      {
        cadReducer.showExFactory
        ?
        <center style={cadCardRowstyle}>
          <b>Earliest Ex-Factory Date: {formatDate(cad.ex_factory_date)}</b>
        </center>
        :
        null
      }

      {cadReducer.showCountryofOrigin && cad.country != null && cad.country != '' ?
        <center style={cadCardRowstyle}>
          <b>Made in {cad.country}</b>
        </center>
      : null}

      {cadReducer.showCasePack && cad.case_pack != null && cad.case_pack != '' ?
        <center style={cadCardRowstyle}>
          <b>QTY per Case Pack: {cad.case_pack}</b>
        </center>
      : null}

      {
        cad.at != null && (isAdmin() || isSales() || isSalesAssistant() || isInventory() || isProduction())
        ?
        <div style={{float: 'right'}}>AT: {cad.at}</div>
        :
        null
      }

    </div>
  )



  return (
    <div>

      {
        imageUrl2 != null
        ?
        <React.Fragment>
          <img src={showSecondImage ?  imageUrl : imageUrl2} style={{position: 'absolute', right: '3px', top: '0px', cursor: 'pointer', height: '50px', width: 'auto'}} onClick={toggleSecondImage}/>
          <button style={{position: 'absolute', right: '3px', top: '0px', cursor: 'pointer', background: 'white', border: '1px solid rgb(222,222,222)', borderRadius: '3px', fontSize: '8px', opacity: 0.5}} onClick={toggleSecondImage}>{showSecondImage ? 'primary' : 'second'} image</button>
        </React.Fragment>
        :
        null
      }

      {
        findPrint(cad.id)
        ?
        showPrintButton(cad.id, togglePrintImage, showPrintImage, imageUrl, imageUrl2)
        :
        null
      }

      {extra}

      {
        isAdmin() || isSales() || isSalesAssistant() || isInventory() || isProduction()
        ?
        editBestSellersButton(cad)
        :
        null
      }
    </div>
  )
}

const updateBestOrPoorSellerData = (bestOrPoor, cad_id, defaultAccount) => {
  //only if empty or can refresh too?
  //check if already called api don't want to call again? or maybe id want to to see updated info?
  //later need to secure route by validating token

  let defaultAccountStr = encodeURIComponent(defaultAccount.join(','))

  if (bestOrPoor == 'best') {
    $.get(process.env.REACT_APP_API_URL + '/api/best_seller_data?cad_id=' + cad_id + '&default_account=' + defaultAccountStr, (result) => {
      setTimeout(() => {
        $('#best-seller-info-'+cad_id).html(result.data)
      }, 100)
    })
  } else if (bestOrPoor == 'average') {
    $.get(process.env.REACT_APP_API_URL + '/api/average_seller_data?cad_id=' + cad_id + '&default_account=' + defaultAccountStr, (result) => {
      setTimeout(() => {
        $('#average-seller-info-'+cad_id).html(result.data)
      }, 100)
    })
  } else {
    $.get(process.env.REACT_APP_API_URL + '/api/poor_seller_data?cad_id=' + cad_id + '&default_account=' + defaultAccountStr, (result) => {
      setTimeout(() => {
        $('#poor-seller-info-'+cad_id).html(result.data)
      }, 100)
    })
  }
}

export {getExtra}

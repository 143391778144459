import React, {Component} from 'react'
import {Button, Popup, Menu, Dropdown, Image} from 'semantic-ui-react'
import {getNumSelected, selectAllItems} from './update-selected'
import {exportOptions} from './export-options'
import BulkEditModal from './bulk-edit-modal'
import {sendClearBulkModalEvent} from './send-clear-bulk-modal-event'
import {checkIfZeroOrPendingPiSelected, checkIfNonPendingRowsSelected, checkIfHaveAlreadyPaidRowsSelected, checkIfAllPendingProcessedSelected, checkIfNonProcessedRowsSelected, checkIfNonQbEnteredRowsSelected, checkIfHaveMultipleFactorySelected, checkIfAnyLockedSelected} from './qb-rows-selected'
import {downloadExcel, downloadPDF} from './download-excel'
import {saveStatus} from './save-status'
import PdfExportModal from './pdf-export-modal'
import IsPaidModal from './is-paid-modal'
import {roundToPenny} from './round-to-penny'
import {totalDollar, reducer} from './total-dollar'
import BulkEditVpoStages from './bulk-edit-stages'
import {buttonStyle} from './button-style'

const styleChangesStyle = {borderBottom: '3px solid black', width: '204px', textAlign: 'center', marginBottom: '5px', marginLeft: '275px'}

class BulkButtons extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      numSelected: 0,
      bulkModalOpen: false,
      onlyAsnXfactoryDateInput: false,
      loading: false,
      pdfExportModalOpen: false,
      isPaidModalOpen: false,
      isPaidModalRefnumber: null,
      isPaidModalTotal: null
    }

    this.selectAllItems = this.selectAllItems.bind(this)
    this.bulkEditItems = this.bulkEditItems.bind(this)
    this.addAsnXfactoryDate = this.addAsnXfactoryDate.bind(this)
    this.bulkPayItems = this.bulkPayItems.bind(this)
    this.bulkModalClose = this.bulkModalClose.bind(this)
    this.updateNumSelected = this.updateNumSelected.bind(this)
    this.export = this.export.bind(this)
    this.stopLoading = this.stopLoading.bind(this)
    this.startLoading = this.startLoading.bind(this)
    this.markProcessed = this.markProcessed.bind(this)
    this.markQbEntered = this.markQbEntered.bind(this)
    this.markShipped = this.markShipped.bind(this)
    this.markCancelled = this.markCancelled.bind(this)
    this.pdfExportModalClose = this.pdfExportModalClose.bind(this)
    this.isPaidModalClose = this.isPaidModalClose.bind(this)
  }

  componentDidMount() {
    window.addEventListener('update-num-selected', this.updateNumSelected)
    window.addEventListener('bulk-vpo-modal-close', this.bulkModalClose)
  }

  selectAllItems() {
    let {results} = this.props
    selectAllItems(results)
    this.updateNumSelected()
  }

  bulkEditItems() {
    let {results} = this.props
    let {numSelected} = this.state

    if (numSelected == 0) {
      alert('Select at least 1 item')
      return
    } else {
      // let allPendingProcessedSelected = checkIfAllPendingProcessedSelected(results)
      // if (!allPendingProcessedSelected) {
      //   alert('Editing only for Pending and Processed status')
      //   return
      // } else {
      //   let anyLockedSelected = checkIfAnyLockedSelected(results)
      //   if (anyLockedSelected) {
      //     alert('CANNOT edit a LOCKED VPO')
      //     return
      //   } else {
          this.setState({bulkModalOpen: true, onlyAsnXfactoryDateInput: false})
          sendClearBulkModalEvent()
      //   }
      // }
    }
  }

  addAsnXfactoryDate() {
    let {results} = this.props
    let {numSelected} = this.state

    if (numSelected == 0) {
      alert('Select at least 1 item')
      return
    } else {
      this.setState({bulkModalOpen: true, onlyAsnXfactoryDateInput: true})
      sendClearBulkModalEvent()
    }
  }

  bulkPayItems() {
    let {results} = this.props
    let {numSelected} = this.state

    if (numSelected == 0) {
      alert('Select at least 1 item')
      return
    } else {
      let haveAlreadyPaidRowsSelected = checkIfHaveAlreadyPaidRowsSelected(results)
      if (haveAlreadyPaidRowsSelected) {
        alert('You have ALREADY PAID items selected')
        return
      } else {
        let selectedRows = results.filter(x => !!window.vpoIds[x['id']])
        let refnumbers = selectedRows.map(x => x['refnumber']).join(', ')
        let total = selectedRows.map(x => roundToPenny(totalDollar(x))).reduce(reducer)
        this.setState({isPaidModalOpen: true, isPaidModalRefnumber: refnumbers, isPaidModalTotal: total})
      }
    }
  }

  markQbEntered() {
    let {results} = this.props
    let {numSelected} = this.state

    if (numSelected == 0) {
      alert('Select at least 1 item')
      return
    } else {
      let haveNonProcessedRowsSelected = checkIfNonProcessedRowsSelected(results)
      if (haveNonProcessedRowsSelected) {
        alert('Can only mark Processed items as QB-Entered')
        return
      } else {
        saveStatus('QB-Entered')
      }
    }
  }

  markProcessed() {
    let {results} = this.props
    let {numSelected} = this.state

    if (numSelected == 0) {
      alert('Select at least 1 item')
      return
    } else {
      let haveNonPendingRowsSelected = checkIfNonPendingRowsSelected(results)
      if (haveNonPendingRowsSelected) {
        alert('Can only mark Pending items as Processed')
        return
      } else {

        let haveZeroOrPendingPiSelected = checkIfZeroOrPendingPiSelected(results)

        let markAnyway = true

        if (haveZeroOrPendingPiSelected) {
          markAnyway = window.confirm('ERROR: Price & Factory required to mark as Processed, MARK ANYWAY?')
        }

        if (markAnyway) {
          saveStatus('Processed')
        }
      }
    }
  }

  markShipped() {
    let {results} = this.props
    let {numSelected} = this.state

    if (numSelected == 0) {
      alert('Select at least 1 item')
      return
    } else {
      let haveNonQbEnteredRowsSelected = checkIfNonQbEnteredRowsSelected(results)
      if (haveNonQbEnteredRowsSelected) {
        alert('Can only mark QB-Entered or Ready-To-Ship items as Shipped')
        return
      } else {
        saveStatus('Shipped')
      }
    }
  }

  markCancelled() {
    let {numSelected} = this.state

    if (numSelected == 0) {
      alert('Select at least 1 item')
      return
    } else {
      if (window.confirm('Are you sure you want to Cancel the items?')) {
        saveStatus('Cancelled')
      }
    }
  }

  bulkModalClose() {
    this.setState({bulkModalOpen: false})
  }

  updateNumSelected() {
    let numSelected = getNumSelected()
    this.setState({numSelected})
    window.numSelected = numSelected
  }

  export(e, {value}) {
    let {numSelected} = this.state
    let {results, visibleFields} = this.props

    if (numSelected == 0) {
      alert('Please select at least one order!')
    } else {
      switch(value) {
        case 'Excel':
          this.setState({loading: true})
          downloadExcel(results, visibleFields, this.stopLoading)

          break
        case 'PDF':
          let haveMultipleFactorySelected = checkIfHaveMultipleFactorySelected(results) //Can only combine PDF for items with same factory
          let haveAlreadyPaidRowsSelected = checkIfHaveAlreadyPaidRowsSelected(results)
          if (numSelected == 1 || haveMultipleFactorySelected || haveAlreadyPaidRowsSelected) {
            this.setState({loading: true})
            downloadPDF(results, this.stopLoading, numSelected > 1)
          } else {
            this.setState({pdfExportModalOpen: true})
          }

          break
      }
    }
  }

  pdfExportModalClose() {
    this.setState({pdfExportModalOpen: false})
  }

  isPaidModalClose() {
    this.setState({isPaidModalOpen: false})
  }

  stopLoading() {
    this.setState({loading: false})
  }

  startLoading() {
    this.setState({loading: true})
  }

  render() {
    let {clearFilters, results, fabricationOptions, repOptions, customer} = this.props
    let {numSelected, bulkModalOpen, onlyAsnXfactoryDateInput, loading} = this.state

    return (
      <div style={{marginBottom: '20px'}}>
      <div style={styleChangesStyle}>Status Changes</div>
        <Popup content='Select all items' position='bottom left'
          trigger={
            <Button basic icon='mouse pointer' onClick={this.selectAllItems} style={buttonStyle}/>
          }
        />

        <Popup content='Bulk edit' position='bottom left'
          trigger={
            <Button basic icon='pencil' onClick={this.bulkEditItems} style={buttonStyle}/>
          }
        />

        <Popup content='Advance Shipping Notice (ASN)' position='bottom left'
          trigger={
            <Button basic icon='ship' onClick={this.addAsnXfactoryDate} style={buttonStyle}/>
          }
        />

        <BulkEditVpoStages numSelected={numSelected}/>

        <Popup content='Bulk pay items' position='bottom left'
          trigger={
            <Button basic icon='dollar' onClick={this.bulkPayItems} style={buttonStyle}/>
          }
        />

        <Popup content='Mark as Processed (Price & Factory required)' position='bottom left' trigger={
          <Button basic color='blue' icon='cog' onClick={this.markProcessed} style={buttonStyle}/>
        }/>

        <Popup content='Mark as QB-Entered' position='bottom left'
          trigger={
            <Button basic color='orange' onClick={this.markQbEntered} style={{paddingLeft: '14px', paddingRight: '14px', paddingTop: '14px', paddingBottom: '13px', marginRight: '10px'}}>
              <Image id='qb-icon-status' src='https://erp-rails-usa-production.s3.amazonaws.com/images/qb_logo_small.png' avatar style={{marginTop: '-2px', marginBottom: '-1px', marginRight: '1px'}}/>
            </Button>
          }
        />

        <Popup content='Mark as Shipped' position='bottom left' trigger={
          <Button basic color='green' icon='check' onClick={this.markShipped} style={buttonStyle}/>
        }/>

        <Popup content='Cancel' position='bottom left' trigger={
          <Button basic color='black' icon='trash' onClick={this.markCancelled} style={buttonStyle}/>
        }/>

        <Menu compact style={{marginTop: '-2px', marginRight: '10px', boxShadow: 'none'}}>
          <Dropdown id="export-dropdown" disabled={loading} loading={loading}
            text={
             numSelected == 0 ? 'Export' : ('Export (' + numSelected + ')')
            }
            options={exportOptions}
            simple item onChange={this.export} value={null}
          />
        </Menu>

        <a onClick={clearFilters} className="cads-reset-filters">Reset filters</a>

        <BulkEditModal results={results} open={bulkModalOpen} onlyAsnXfactoryDateInput={onlyAsnXfactoryDateInput}
          onClose={this.bulkModalClose} fabricationOptions={fabricationOptions}
          repOptions={repOptions} customer={customer}
        />

        {
          this.state.isPaidModalOpen
          ?
          <IsPaidModal
            open={this.state.isPaidModalOpen}
            onClose={this.isPaidModalClose}
            refnumber={this.state.isPaidModalRefnumber}
            total={this.state.isPaidModalTotal}
          />
          :
          null
        }

        {
          this.state.pdfExportModalOpen
          ?
          <PdfExportModal
            open={this.state.pdfExportModalOpen}
            onClose={this.pdfExportModalClose}
            results={results}
            startLoading={this.startLoading}
            stopLoading={this.stopLoading}
          />
          :
          null
        }

      </div>
    )
  }
}

export default BulkButtons

import $ from 'jquery'

const getBestSellerData = (defaultAccount, callback) => {
  let defaultAccountStr = encodeURIComponent(defaultAccount.join(','))
  let url = `${process.env.REACT_APP_API_URL}/api/best_seller_for_account?defaultAccount=${defaultAccountStr}`

  $.get(url, (result) => {
    window.salesAccountBestSellersDates = result.sales_account_best_sellers;
    window.salesAccountBestSellers = Object.keys(result.sales_account_best_sellers).map(Number);

    window.salesAccountAverageSellersDates = result.sales_account_average_sellers;
    window.salesAccountAverageSellers = Object.keys(result.sales_account_average_sellers).map(Number);

    window.salesAccountPoorSellersDates = result.sales_account_poor_sellers;
    window.salesAccountPoorSellers = Object.keys(result.sales_account_poor_sellers).map(Number);

    callback()
  })
}

export {getBestSellerData}
